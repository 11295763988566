import StateFormSelect from './StateFormSelect';

export default function BillingAddressForm(props) {
    return (
        <div className="row g-3">
        {/*<div className="contain-div">*/}
        <div className="col-sm-6">
            <label htmlFor="firstName" className="form-label">First name</label>
            <input type="text" className="form-control" id="firstName" placeholder=""
                required />
            <div className="invalid-feedback">
                Valid first name is required.</div>
        </div>

        <div className="col-sm-6">
            <label htmlFor="lastName" className="form-label">Last name</label>
            <input type="text" className="form-control" id="lastName" placeholder=""
                required />
            <div className="invalid-feedback">
                Valid last name is required.</div>
        </div>
        <div className="col-12">
            <label htmlFor="email" className="form-label">Email </label>
            <input type="email" className="form-control" id="email" placeholder="you@example.com" required />
            <div className="invalid-feedback">
                Please enter a valid email address for shipping updates.</div>
        </div>
        <div className="col-12">
            <label htmlFor="phone" className="form-label">Phone </label>
            <input type="phone" className="form-control" id="phone" placeholder="18005551212" required />
            <div className="invalid-feedback">
                Please enter a valid phone number for receipt.</div>
        </div>

        <div className="col-12">
            <label htmlFor="address" className="form-label">Address</label>
            <input type="text" className="form-control" id="address" placeholder="1234 Main St"
                required />
            <div className="invalid-feedback">
                Please enter your shipping address.</div>
        </div>

        <div className="col-12">
            <label htmlFor="address2" className="form-label">Address 2 <span
                className="text-muted">(Optional)</span></label>
            <input type="text" className="form-control" id="address2"
                placeholder="Apartment or suite" />
        </div>

        <div className="col-md-5">
            <label htmlFor="country" className="form-label">Country</label>
            <select className="form-select" id="country" required>
                <option value="">Choose...</option>
                <option>United States</option></select>
            <div className="invalid-feedback">
                Please select a valid country.</div>
        </div>
            <StateFormSelect id="state" isRequired={true}/>
        <div className="col-md-3">
            <label htmlFor="city" className="form-label">City</label>
            <input type="text" className="form-control" id="city" placeholder="" required />
            <div className="invalid-feedback" >
                City required.</div>
        </div>
        <div className="col-md-3">
            <label htmlFor="zip" className="form-label">Zip</label>
            <input type="text" className="form-control" id="zip" placeholder="" required />
            <div className="invalid-feedback" >
                Zip code required.</div>
        </div>
    </div>
    );
}
