import axios from "axios";

const StudentsService = {

    async getFundraiserDevice(guid) {
        const response = await fetch('api/Students/GetDeviceInfo?guid=' + guid);
        const data = await response.json();
        return data;
    },
    async UpdateOptInStatus(optInStatusDTO) {
        console.log(optInStatusDTO);
        return axios.post('api/Students/UpdateOptInStatus',optInStatusDTO);
    },
}

export default StudentsService;