import React, { useState, useRef } from 'react';
import TicketsService from "../services/TicketsService";

export default function TicketPurchase({ tp }) {
    const [shareMessage, setShareMessage] = useState('');
    const rootUrl = window.location.origin;
    const ticketLink = `${rootUrl}/activateticket/${tp.ticketPurchaseSessions[0].guid}`;
    const phoneRef = useRef();

    const handleShareTicketPurchase = (guid) => {
        let phoneNumber = phoneRef.current.value.replace(/[^\d]/g, ''); // remove all non-digit characters
        if (phoneNumber.length !== 10 && phoneNumber.length !== 11) { // validate that the input contains exactly 10 or 11 digits
            setShareMessage("Please enter a valid 10-digit phone number.");
            return;
        }

        TicketsService.shareTicketPurchase(guid, phoneNumber)
            .then(response => setShareMessage(response.message))
            .catch(err => {
                console.error(err);
                setShareMessage("Could not send the link. Please check the phone number and try again.");
            });
    }
    const handleCopyLink = () => {
        navigator.clipboard.writeText(ticketLink);
        setShareMessage("Ticket link copied to clipboard!");
    }

    return (
        <div className="container py-5">
            <h5 className="mb-3">Your Ticket</h5>
            <div className="mb-3">
                <button
                    className="btn btn-primary"
                    onClick={handleCopyLink}
                    disabled={tp.ticketPurchaseSessions.some(tps => tps.keyActivatedAt)}
                    title="Click to copy the ticket link"
                >
                    Copy Link
                </button>
            </div>
            <div className="mb-3">
                <input
                    ref={phoneRef}
                    name="phone"
                    type="tel"
                    placeholder="Enter phone number to share ticket"
                    required
                    className="form-control me-2"
                />
            </div>
            <div className="mb-3">
                <button
                    type="submit"
                    className="btn btn-info"
                    onClick={() => handleShareTicketPurchase(tp.guid)}
                    disabled={tp.ticketPurchaseSessions.some(tps => tps.keyActivatedAt)}
                    title="Send the ticket link to a phone number"
                >
                    Send Link to Phone
                </button>
            </div>
            {tp.ticketPurchaseSessions.some(tps => tps.keyActivatedAt) && (
                <div className="alert alert-success" role="alert">
                    Ticket Redeemed by: {tp.phone}
                </div>
            )}
            {shareMessage && (
                <div className={`alert ${shareMessage.startsWith('Could not') ? 'alert-danger' : 'alert-success'}`} role="alert">
                    {shareMessage}
                </div>
            )}
        </div>
    );
}
