import React, { useEffect, useState } from "react";
import TicketsService from "../services/TicketsService";
import { useParams, useNavigate } from "react-router-dom";
import ConfirmationModal from "../components/ConfirmationModal";
import SampleWatermark from "../components/SampleWatermark";

export default function TicketRedemption() {
    //useParams
    const { ticketOfferId } = useParams();
    const { ticketPurchaseSessionGuid } = useParams();
    const [modalsOpen, setModalsOpen] = useState(false);
    //useState
    const [ticketOffer, setTicketOffer] = useState();
    const [isExhausted, setIsExhausted] = useState();
    const [purchaseGuid, setPurchaseGuid] = useState();
    const navigate = useNavigate();
    const itemOptionImage = {
        width: "auto",
        maxWidth: "300px",
        height: "auto",
        maxHeight: "300px",
        objectFit: "contain",
    };
    const checkIfExhausted = (x) => {
        let isExpended = x.ticketQty && x.ticketPurchaseOfferRedemptions && x.ticketQty <= x.ticketPurchaseOfferRedemptions.length;
        setIsExhausted(isExpended);
        return (!isExpended)
    };
    const DesignRedeemButton = () => {
        return (
            !isExhausted ?
                <button type="button" onClick={tryOpen} className="btn btn-danger w-75 mb-10">Redeem</button>
                : <button type="button" onClick={tryOpen} className="w-75 mb-10">Redeemed</button>
        )
    };
    useEffect(
        (onLoad) => {
            TicketsService.getTicketOfferDetails(ticketPurchaseSessionGuid, ticketOfferId)
                .then((x) => {
                    setTicketOffer(x);
                    checkIfExhausted(x);
                    if (x.PurchaseGuid !== null)
                        setPurchaseGuid(x.purchaseGuid);
                });
        },
        [modalsOpen]
    );
    const tryOpen = () => {
        TicketsService.getTicketOfferDetails(ticketPurchaseSessionGuid, ticketOfferId).then(
            (x) => {
                setModalsOpen(checkIfExhausted(x));
            }
        );
    };

    return (
        <div className="d-flex flex-column mb-10 mx-auto">
            {/*<SampleWatermark showWatermark={ticketOffer && !purchaseGuid} />*/}
                <div className="card align-center mx-auto" style={{ width: '26rem' }}>
                    {ticketOffer?.vendorOffer.photoUrl && (

                        <img className="card-img-top"
                            src={"https://fundraising-u-production.s3.amazonaws.com/" + ticketOffer?.vendorOffer.photoUrl}
                            alt="ticketOffer.vendorOffer.photoUrl"></img>
                    )}
                    {ticketOffer?.vendorOffer?.vendor.photoUrl && !ticketOffer?.vendorOffer.photoUrl && (

                        <img className="card-img-top"
                            src={"https://fundraising-u-production.s3.amazonaws.com/" + ticketOffer?.vendorOffer?.vendor.photoUrl}
                            alt="ticketOffer.vendorOffer.photoUrl"></img>
                )}
                {ticketOffer && !purchaseGuid && (
                    <div className="row d-flex justify-content-center text-center" style={{color:'red', fontSize:'30px', fontWeight:'bold'}}>SAMPLE - For Preview Only</div>
                    )

                }
                    <div className="card-body">
                        <div className="row d-flex justify-content-center text-center">
                            <h3>{ticketOffer && ticketOffer.vendorOffer.name}</h3>
                            <p>{ticketOffer && ticketOffer.vendorOffer.description}</p>
                        </div>
                        <h5 className="text-left">To redeem offer please follow the steps below:</h5>
                        <p className="mt-0 pt-0 text-left">1. Click Redeem.
                            <br />2. Show the redemption pop-up at register, so they can verify your discount.
                        </p>
                        <div className="row d-flex justify-content-center">
                            {DesignRedeemButton()}
                            <button onClick={() => navigate(-1)} className="btn btn-primary w-75 mb-10 ">Back</button>
                        </div>
                    </div>
                </div>
                <ConfirmationModal
                    ticketOffer={ticketOffer}
                    ticketPurchaseSessionGuid={ticketPurchaseSessionGuid}
                purchaseGuid={purchaseGuid }
                    modalStatus={modalsOpen}
                    deactivate={() => {
                        setModalsOpen(false);
                    }}
                />
            </div>
    );
}
