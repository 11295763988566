import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import TicketUserBottomNav from '../components/TicketUserBottomNav';
import TicketsService from '../services/TicketsService';
import { checkSession } from '../components/UserTicketSecurity';
import { getTicketUserBottomNavProps, getBackgroundStyle } from '../components/TeamStyling'; 
import { useWebAppManifest } from '../hooks/WebAppManifest';

export default function UserTeam() {
    const { ticketPurchaseSessionGuid } = useParams();
    const [ticket, setTicket] = useState();
    const [show, setShow] = useState(false);
    const [ticketPurchase, setTicketPurchase] = useState();
    useWebAppManifest();
    const fetchTicket = async (sessionGuid) => {
        const ticket = await TicketsService.getTicket(sessionGuid);
        setTicket(ticket);
        if (ticket?.ticketPurchases && ticket.ticketPurchases.length > 0) {
            setTicketPurchase(ticket.ticketPurchases[0]);
        }
        setShow(true);
    };

    useEffect(() => {
        checkSession(ticketPurchaseSessionGuid, fetchTicket);
    }, []);

    if (!show) return null;

    const navigate = (url) => {
        window.location.href = url;
    };

    const team = ticket?.fundraiser?.team;
    const teamName = team && ticket.fundraiser.school && ticket.fundraiser.school.name ? ticket.fundraiser.school.name + " " + team.name + " Resources" : "";
    const backgroundStyle = getBackgroundStyle(team);
    return (
        <div style={{ flex: 1, ...backgroundStyle }}>
            <div style={{ height: "100vh", flex: 1 }}>
                <h2 style={{ textAlign: "center" }} className="fs-4">{teamName}</h2>
                <ul className="list-group mb-100" style={{ marginBottom: "56px" }}>
                    {ticket && ticket.teamLink2Url && ticket.teamLink2Name ? <button className="list-group list-group-item text-left" style={{ backgroundColor: 'inherit' }} onClick={() => navigate(ticket.teamLink2Url)}>{ticket.teamLink2Name}</button> : <></>}
                    {ticket && ticket.teamLink3Url && ticket.teamLink3Name ? <button className="list-group list-group-item text-left" style={{ backgroundColor: 'inherit' }} onClick={() => navigate(ticket.teamLink3Url)}>{ticket.teamLink3Name}</button> : <></>}
                    {ticket && ticket.teamLink1Url && ticket.teamLink1Name ? <button className="list-group list-group-item text-left" style={{ backgroundColor: 'inherit' }} onClick={() => navigate(ticket.teamLink1Url)}>{ticket.teamLink1Name}</button> : <></>}
                </ul>
            </div>
            <TicketUserBottomNav
                ticketPurchaseSessionGuid={ticketPurchaseSessionGuid}
                {...getTicketUserBottomNavProps(team)}
                ticketPurchaseGuid={ticketPurchase?.guid}
            />
        </div>

    );
}