import OrderItemOption from '../components/OrderItemOption';
import OrderItemModal from '../components/OrderItemModal';
import { useState } from 'react';

export default function OrderItem(props) {
    const item = props.item;
    const incCount = props.incCount;
    const decCount = props.decCount;
    const getImage = (url) => { return <img style={pictureFit} src={"https://fundraising-u-production.s3.amazonaws.com/" + url} alt="img"></img> }
    const changeImage = (url) => { setModalUrl(url) }
    const [modalUrl, setModalUrl] = useState(item.itemImage);
    const [modalStatus, setModalStatus] = useState(false);
    const deactivateModal = () => { setModalStatus(false) };
    const activateModal = (url) => {
        changeImage(url);
        setModalStatus(true)
    };
    const pictureFit = {
        width: '350px',
        height: '525px',
        objectFit: 'contain'
    }
    return (
        <div>
            <button className="btn btn-primary btn-lg col-12" type="button" onClick={() => { activateModal(item.itemImage) }}>Show Selection</button>
            <h2 className="mt-3">{item.messageTextName}</h2>
            <OrderItemModal modalStatus={modalStatus} deactivate={deactivateModal} contents={getImage(modalUrl)} />
            <ul className="list-group mb-3">
                {item.fundraiserOrderItemOptions.map((option, idx) => {
                    return (
                        <div key={idx}>
                            <OrderItemOption activate={activateModal} option={option} itemId={props.itemId} increase={() => incCount(option)} decrease={() => decCount(option)} fundraiserId={props.fundraiserId} />
                        </div>
                    )
                })}
            </ul>
        </div>
    );
}
