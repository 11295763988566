import StateFormSelect from './StateFormSelect';

export default function DeliveryAddressForm(props) {
    return (
        <div className={props.show ? "row g-3" :'d-none'}>
            {/*<div className="contain-div">*/}
            <div className="col-sm-6">
                <label htmlFor="deliveryfirstName" className="form-label">First name</label>
                <input type="text" className="form-control" id="deliveryfirstName" placeholder=""
                    required={props.show} />
                <div className="invalid-feedback">
                    Valid first name is required.
                </div>
            </div>
            <div className="col-sm-6">
                <label htmlFor="deliverylastName" className="form-label">Last name</label>
                <input type="text" className="form-control" id="deliverylastName" placeholder=""
                    required={props.show} />
                <div className="invalid-feedback">
                    Valid last name is required.
                </div>
            </div>
            <div className="col-12">
                <label htmlFor="deliveryaddress" className="form-label">Address</label>
                <input type="text" className="form-control" id="deliveryaddress" placeholder="1234 Main St"
                    required={props.show} />
                <div className="invalid-feedback">
                    Please enter your shipping address.
                </div>
            </div>
            <div className="col-12">
                <label htmlFor="deliveryaddress2" className="form-label">Address 2 <span
                    className="text-muted">(Optional)</span></label>
                <input type="text" className="form-control" id="deliveryaddress2"
                    placeholder="Apartment or suite" />
            </div>

            <div className="col-md-5">
                <label htmlFor="deliverycountry" className="form-label">Country</label>
                <select className="form-select" id="deliverycountry"
                    required={props.show}>

                    <option value="">Choose...</option>
                    <option>United States</option>
                </select>
                <div className="invalid-feedback">
                    Please select a valid country.
                </div>
            </div>

            <StateFormSelect id="deliverystate" isRequired={props.show}/>
            <div className="col-md-3">
                <label htmlFor="deliverycity" className="form-label">City</label>
                <input type="text" className="form-control" id="deliverycity" placeholder=""
                    required={props.show} />
                <div className="invalid-feedback" >
                    City required.</div>
            </div>
            <div className="col-md-3">
                <label htmlFor="deliveryzip" className="form-label">Zip</label>
                <input type="text" className="form-control" id="deliveryzip" placeholder=""
                 required={props.show} />
                <div className="invalid-feedback" >
                    Zip code required.
                </div>
            </div>
        </div>
        )
}