// UserTicket.js
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Collage from '../components/Collage';
import TicketUserBottomNav from '../components/TicketUserBottomNav';
import TicketsService from '../services/TicketsService';
import { checkSession } from '../components/UserTicketSecurity';
import { getTicketUserBottomNavProps, getBackgroundStyle } from '../components/TeamStyling'; 
import { useWebAppManifest } from '../hooks/WebAppManifest';

export default function UserTicket(props) {
    const { ticketPurchaseSessionGuid } = useParams();
    const [ticket, setTicket] = useState();
    const [ticketPurchase, setTicketPurchase] = useState();
    const [show, setShow] = useState(false);

    useWebAppManifest();


    const fetchTicket = async (sessionGuid) => {
        const ticket = await TicketsService.getTicket(sessionGuid);
        setTicket(ticket);
        if (ticket?.ticketPurchases && ticket.ticketPurchases.length > 0) {
            setTicketPurchase(ticket.ticketPurchases[0]);
        }
        setShow(true);
    };

    useEffect(() => {
        checkSession(ticketPurchaseSessionGuid, fetchTicket);
    }, []);

    if (!show) return null;

    const team = ticket?.fundraiser?.team;
    console.log(team);
    const backgroundStyle = getBackgroundStyle(team);
    const getImages = () => {
        let urlArray = [];
        if (ticket?.photoUrl && ticket?.photoUrl !== "")
            urlArray.push(ticket.photoUrl);
        if (ticket?.photoUrl2 && ticket?.photoUrl2 !== "")
            urlArray.push(ticket.photoUrl2);
        if (ticket?.photoUrl3 && ticket?.photoUrl3 !== "")
            urlArray.push(ticket.photoUrl3);
        if (ticket?.photoUrl4 && ticket?.photoUrl4 !== "")
            urlArray.push(ticket.photoUrl4);
        if (ticket?.photoUrl5 && ticket?.photoUrl5 !== "")
            urlArray.push(ticket.photoUrl5);
        return urlArray;
    };

    const schoolName = ticket?.fundraiser?.school?.name;
    const teamName = team?.name;
    const headerText = schoolName && teamName ? schoolName + " " + teamName : "";

    return (
        <div style={{ ...backgroundStyle}}>
            <div id="content" style={{ display: "flex", flexDirection: "column" }}>
                <div className="d-flex flex-column">
                    <h2 style={{ textAlign: "center" }} className="fs-4">{headerText}</h2>
                </div>
                <div style={{ paddingBottom: '130px' }}>
                    <Collage images={getImages()} />
                </div>
            </div>
            <TicketUserBottomNav
                ticketPurchaseSessionGuid={ticketPurchaseSessionGuid}
                {...getTicketUserBottomNavProps(team)}
                ticketPurchaseGuid={ticketPurchase?.guid}
            />
        </div>
    );
}
