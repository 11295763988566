import React, { useEffect, useState } from 'react';
import TicketsService from "../services/TicketsService";
import { useParams } from "react-router-dom";
import OfferItem from '../components/OfferItem';
import TicketUserBottomNav from '../components/TicketUserBottomNav';
import { checkSession } from '../components/UserTicketSecurity';
import { useWebAppManifest } from '../hooks/WebAppManifest';


export default function UserOffers() {
    //useParams
    const { ticketPurchaseSessionGuid } = useParams();
    //useState
    const [ticketGuid, setTicketGuid] = useState();
    const { fundraiserId } = useParams();
    const [offers, setOffers] = useState();
    const [show, setShow] = useState(false);
    useWebAppManifest();
    const salesDisplayTicket = (fId) => {

        TicketsService.getFundraisersTicket(fId).then(x => {
            console.log(x);
            checkSession(x.guid, fetchOffers);
            setTicketGuid(x.guid);
        });
    }
    const fetchOffers = async (sessionGuid) => {
            TicketsService.getOffers(sessionGuid).then(x => {
                setOffers(x);
                setShow(true);
            });
    }
    useEffect(() => {
        if (fundraiserId != null)
        {
            salesDisplayTicket(fundraiserId);
        }
        else
        {
            checkSession(ticketPurchaseSessionGuid, fetchOffers);
        }
    }, []);

    if (!show)
        return null;

    return (
        <div>
            <div className="content" id="content" style={{ display: "flex", flexDirection: "column", flex: 1, paddingBottom: "130px" }}>
                <div>
                    <a href="https://fundraisingu.enjoymydeals.com/">
                        <img style={{ width: '100%' }}
                            src={"https://fundraising-u-production.s3.amazonaws.com/fileuploads/Vendor/222/DT_NATOFFERS.png"}
                            alt="national offers">
                        </img>
                    </a>
                </div>
                <p>{offers && offers.id}</p>
                <h2 style={{textAlign: "center"} }>Your Offers</h2>
                <ul className="list-group" style={{ flex: 1 }}>
                    {offers &&
                        offers.map((offer, index) => {
                            let validOffers = offer.ticketQty - offer.ticketPurchaseOfferRedemptions.length;
                            let invalidOffers = offer.ticketQty - validOffers;
                            let validOfferItems = Array.from({ length: validOffers }, (_, i) => {
                                return (
                                    <OfferItem
                                        key={`valid-offer-${index}-${i}`}
                                        ticketPurchaseSessionGuid={ticketPurchaseSessionGuid ? ticketPurchaseSessionGuid : ticketGuid}
                                        offer={offer}
                                    />
                                );
                            });
                            let invalidOfferItems = Array.from({ length: invalidOffers }, (_, i) => {
                                return (
                                    <OfferItem
                                        key={`invalid-offer-${index}-${i}`}
                                        ticketPurchaseSessionGuid={ticketPurchaseSessionGuid ? ticketPurchaseSessionGuid : ticketGuid}
                                        offer={offer}
                                        forcedDisable={true}
                                    />
                                );
                            });
                            return [...validOfferItems, ...invalidOfferItems];
                        })}
                </ul>
            </div>
            <TicketUserBottomNav ticketPurchaseSessionGuid={ticketPurchaseSessionGuid ? ticketPurchaseSessionGuid : ticketGuid} />
        </div>
    );

}
