import React, { Component, useEffect, useState } from 'react';
import Db from "../services/OrdersService";
import { Link, useParams } from 'react-router-dom';

export default function SelectState(props) {
    //const [purchaseId, setPurchaseId] = useState(11);
    //const [orders, setOrders] = useState();
    const [myFundraiser, setMyFundraiser] = useState();
    const { fdid } = useParams();

    useEffect(x => {
        Db.getMyFundraiser(fdid).then(x => {
            console.log(x);
            //setFundraiserDevice(x);
            setMyFundraiser(x);
            //FundraisersService.getFundraiserDetails(x.fundraiserId).then(y => setFundraiser(y));
        });
    }, []);



    return (
        <div>
            {/*{Db.location}*/}
            {/*{Db.school.name}*/}
            <h2>{myFundraiser?.fundraiserDevice?.fundraiser?.name}</h2>
            <h4>Orders: {myFundraiser?.ordersQty} - Total: ${myFundraiser?.ordersTotal}</h4>
            <ul className="list-group">

                {myFundraiser?.fundraiserDevice?.orders && myFundraiser?.fundraiserDevice?.orders.map((x, index) => {
                    console.log(x);
                    return (
                        <li key={index} className="list-group-item">
                            {/*<div className="row">*/}
                            {/*    <div className="col">*/}
                            {/*        {x.donor.firstName} {x.donor.lastName} - Phone: {x.donor.phone}*/}
                            {/*    </div>*/}
                            {/*    <div className="col" style={{alignItems:'right'} }>*/}
                            {/*        {x.donor.address}, {x.donor.address2}, {x.donor.city}, {x.donor.state}*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="row">*/}
                            <table className="table border">
                                <tbody>
                                    <tr>
                                        <th>{x.donor.firstName} {x.donor.lastName} - Phone: {x.donor.phone}</th>
                                        <th>Quantity</th>
                                        <th>Price</th>
                                        <th>Total</th>
                                    </tr>
                                    {x && x.ordersItems && x.ordersItems.map((item, index) => {
                                        return (
                                            <tr key={index} className="border">
                                                <td>{item.name}</td>
                                                <td>{item.amount}</td>
                                                <td>${item.price}</td>
                                                <td>${item.price * item.amount}</td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td><strong>Total</strong></td>
                                        <td></td>
                                        <td></td>
                                        <td><strong>${x && x.orderTotal}</strong></td>
                                    </tr>
                                </tbody>
                            </table>
                            {/*</div>*/}
                        </li>
                    )
                })}

                {/*<li className="list-group-item"><Link onClick={() => Db.team = "Lacross"} to="/Order">Lacross</Link></li>*/}
                {/*<li className="list-group-item">Baseball</li>*/}
            </ul>
        </div>
    );

}
