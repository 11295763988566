import ItemCounter from './ItemCounter';

export default function OrderItemOption({ increase, decrease, option, activate, fundraiserId }) {
    const itemOptionImage = {
        width: 'auto',
        height: 'auto',
        objectFit: 'contain'
    };
    const isPreviewAvailable = option.itemOptionTypeId > 0;

    const windowWidthAdjustment = window.innerWidth <= 400 ? 0.1 : 0;

    const previewButtonStyle = {
        fontSize: `${0.8 - windowWidthAdjustment}rem`,
        padding: `${0.2 - windowWidthAdjustment}rem ${0.5 - windowWidthAdjustment}rem`,
        marginTop: `${0.5 - windowWidthAdjustment}rem`,
    };

    const openPreview = () => window.open(`TicketPreview/${fundraiserId}`, "_blank");

    const priceStyle = {
        display: 'flex',
        justifyContent: 'right',
        alignItems: 'right',
        height: '100%' // Adjust if needed to ensure full height of the container
    };

    return (
        <div>
            <li className="list-group-item d-flex justify-content-between lh-sm">
                <div className="col-3 d-flex flex-column">
                    <img
                        style={itemOptionImage}
                        onClick={() => activate(option.imgUrl)}
                        src={`https://fundraising-u-production.s3.amazonaws.com/${option.imgUrl}`}
                        alt="itemOptionImage"
                    />
                    {isPreviewAvailable && (
                        <button
                            type="button"
                            className="btn btn-danger"
                            style={previewButtonStyle}
                            onClick={openPreview}
                        >
                            Preview
                        </button>
                    )}
                </div>
                <div className="col-3 d-flex flex-column">
                    <h6 className="my-0 text-left" >
                        {option.name}
                    </h6>
                </div>
                <span className="text-muted px-2 col-2" style={priceStyle}>
                    ${option.price}
                </span>
                <ItemCounter className="col-2"
                    increaseCount={increase}
                    decreaseCount={decrease}
                    quantity={option.qty}
                />
            </li>
        </div>
    );
}
