
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import {Modal, ModalBody } from "reactstrap"; 
import './counter.module.css';

export default function OrderItemModal(props) {
    const contents = props.contents;
    // Modal open state
    const isOpen = props.modalStatus;
    // Toggle for Modal
    const close = () => { props.deactivate() };

    const justImage = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 0
    }
    return (
        <div>
            <Modal isOpen={isOpen} toggle={close} >
                    <ModalBody  onClick={() => close()} style={justImage}> {contents} </ModalBody>
            </Modal>
        </div >
    );
}