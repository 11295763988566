import React, {useEffect, useState } from 'react';
import FundraisersService from "../services/FundraisersService";
import { Link, useParams } from 'react-router-dom';
import Breadcrumb from '../components/BreadcrumbComponent';

export default function SelectSchool() {
    //useParams
    const {location} = useParams();
    const {itemId} = useParams();
    const { phone } = useParams();
    //useState
    const [schools, setSchools] = useState();
    useEffect(x => {
        FundraisersService.getSchools(location, itemId).then(
            x => {
                setSchools(x)

            });
    }, []);
    //useEffect(x => {
    //    FundraisersService.getSchools(location, itemId).then(x => setSchools(x));
    //}, []);

    return (
        <div>
            <h2>Select Fundraiser's School</h2>
            <Breadcrumb location={location && location}/>
            <ul className="list-group">
                {schools && schools.map((x, index) => {
                    return (<Link to={"/SelectTeam/" + x.id + "/" + itemId + "/" + phone} key={index} className="list-group-item list-group-item-action">{x.name}</Link>)
                })}
            </ul>
        </div>
    );
}
