import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, ModalBody } from "reactstrap";
import './counter.module.css';
import { useState } from 'react';
import TicketsService from '../services/TicketsService';
import { useNavigate } from "react-router-dom";

export default function ConfirmationModal(props) {
    const itemOptionImage = {
        width: 'auto',
        maxWidth: '300px',
        height: 'auto',
        maxHeight: '300px',
        objectFit: 'contain'
    }
    // Modal open state
    const ticketOffer = props.ticketOffer;
    const modal = props.modalStatus;
    const purchaseGuid =  props.purchaseGuid ;
    // Toggle for Modal
    const close = () => { props.deactivate() };
    const [isExhausted, setIsExhausted] = useState(false); 
    const DesignRedeemButton = () => {
        if (!purchaseGuid)
            return (<button type="button" disabled className="w-75 mb-10">Preview</button>)

        return (
           
            !isExhausted && props.ticketPurchaseSessionGuid ?
                <button type="button" onClick={redeemTicketPurchaseOffer} className="btn btn-danger w-75 mb-10">Redeem</button>
                : <button type="button" disabled className="w-75 mb-10">Redeemed</button>
        )
    };
    const redeemTicketPurchaseOffer = () =>
    {
        TicketsService.redeemTicketPurchaseOffer(props.ticketPurchaseSessionGuid, props.ticketOffer.id).then(x => setIsExhausted(true));
    }
    return (
        <div>
            <Modal isOpen={modal} toggle={close}>
                <ModalBody>
                    <div className="row d-flex justify-content-center text-center">
                        <h2>Present this at Checkout:</h2>
                        <div className="card align-center mx-auto" style={{ width: '26rem' }}>
                            <img
                                className="card-img-top"
                                src={"https://fundraising-u-production.s3.amazonaws.com/" + ticketOffer?.vendorOffer?.photoUrl2}
                                alt="No coupon code necessary."
                                onError={(e) => { e.target.style.display = 'none' }}
                            ></img>
                            <div className="card-body">
                                <div className="row d-flex justify-content-center text-center">
                                    <h3>{ticketOffer && ticketOffer.vendorOffer.name}</h3>
                                    <p>{ticketOffer && ticketOffer.vendorOffer.description != "Description"&& ticketOffer.vendorOffer.description}</p>
                                </div>
                                {ticketOffer && !purchaseGuid ? (
                                    <div className="row d-flex justify-content-center text-center" style={{ color: 'red', fontSize: '30px', fontWeight: 'bold' }}>SAMPLE - For Preview Only </div>
                                ) :
                                    (
                                        <div className=" text-center row d-flex justify-content-center text-center">
                                            <h1 className="mb-0 text-center">Are you sure you want to redeem this offer?</h1>
                                            <p className="text-center">Once redeemed, you cannot use this offer again.<br /></p>
                                        </div>
                                        )
                                }

                               
                                <div className="row d-flex justify-content-center">
                                    {DesignRedeemButton()}
                                    <button onClick={() => close()} className="btn btn-primary w-75 mb-10 ">Back</button>
                                </div>
                                </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div >
    );
}