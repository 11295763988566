import { Link } from "react-router-dom";
import { useState } from 'react';
export default function OfferItem(props) {
    const testUrl = "codecademy-content/projects/make-a-website/lesson-4/jumbotron.jpg";
    const vendor = props.offer.vendorOffer.vendor;
    const vendorOffer = props.offer.vendorOffer;
    const IsInactive = props.forcedDisable || (props.offer.ticketPurchaseOfferRedemptions.length > props.offer.ticket_qty && vendorOffer.offerTypeId == 1);
    const symbol = IsInactive ? "" : ">";
    const symbolDec = IsInactive ? "text-danger" : "my-0 text-left";
    const styling = "d-flex justify-content-center list-group-item list-group-item-secondary m-0 p-0"
    const throwAlert = () => {
        if (IsInactive)
            alert("This offer has already been redeemed")
    }
    const linkedItem = (content) => {
        return (<Link style={{ border: "0px", backgroundColor:"red" }} to={/MyOffers/ + props.ticketPurchaseSessionGuid + /Redeem/ + props.offer.id}>{content}</Link>);
    }
    const inactiveItem = (content) => {
        return (<div onClick={() => throwAlert()}>{content}</div>);
    }
    const content =
        <>
            <div className="d-flex justify-content-center list-group-item m-0 p-0" style={{ border: "0px" }}>
                <div className="col-12 d-flex flex-column my-0 p-0" style={{ border: "0px" }}>
                    <h3 className="m-10 h-10 m-0 fs-6">{vendor && vendor.name}</h3>
                </div>
            </div>
            <div className="d-flex justify-content-center list-group-item m-0 p-0" style={{ border: "0px" }}>
                <div className="col-4 d-flex flex-column my-0 p-0" style={{ border: "0px", minHeight:"100px" }}>
                    <img className="m-10 h-90 my-auto"
                        style={{maxHeight:"80px", maxWidth:"80px"}}
                        src={"https://fundraising-u-production.s3.amazonaws.com/" + vendor.photoUrl}
                        /*src={vendorOffer.photoUrl}*/
                        alt="vendor.photoUrl">
                    </img>
                </div>
                <div className="col-6 d-flex flex-column" style={{ border: "0px" }}>
                    <h3 className="mt-10 text-left fs-6 h-auto">{vendorOffer.name}</h3>
                    {/*<p className="d-flex lh-sm h-auto">{vendorOffer.description}</p>*/}
                </div>
                <div className=" col-2 my-auto" style={{ border: "0px" }}>
                    <h1 className={symbolDec} >{symbol}</h1>
                </div>
            </div>
          
        </>
    return (
        <li className="p-0 list-group-item">
            {IsInactive ? inactiveItem(content) : linkedItem(content)}
        </li>
    );
}