import React, { Component, useEffect, useState } from 'react';
import OrdersService from "../services/OrdersService";
import { Link, useParams, useNavigate  } from 'react-router-dom';
import StudentsService from "../services/StudentsService";
import OptInModal from '../components/OptInModal'; // Import OptInModal

export default function Fundraisers(props) {
    const [fundraiserDevices, setFundraiserDevices] = useState([]);
    const [currentFundraiserDevice, setCurrentFundraiserDevice] = useState(null);
    const [showOptInModal, setShowOptInModal] = useState(false);
    const { fdid } = useParams();
    const navigate = useNavigate();

    const getFundraiserDevice = (fdid) => {

        StudentsService.getFundraiserDevice(fdid).then(device => {
            setCurrentFundraiserDevice(device);
            console.log(device);
            if (device && device.isOptedIn === null) {
                setShowOptInModal(true);
            }
        });

    }

    useEffect(() => {
        OrdersService.getFundraisersForDevice(fdid).then(data => {
            setFundraiserDevices(data);
            // Assuming the 'fdid' is the ID of the current fundraiser device
            if (data && data.length > 0) {
                getFundraiserDevice(fdid);
            }
        });
    }, [fdid]);

    const newFundraiserDevice = (e) => {
        OrdersService.NewFundraiserDevice().then(x => navigate("/fundraisers/" + fdid));

    };
    const closeModalUpdateDevice = (isOpen) => {
        setShowOptInModal(isOpen);
        getFundraiserDevice(fdid);
    }
    return (
        <div>
            <h2>My Fundraisers</h2>
            <ul className="list-group">

                {fundraiserDevices && fundraiserDevices.map((x, index) => {
                    return (
                        <li key={index} className="list-group-item">
                            <div>
                                <Link to={"/Fundraiser/" + x.fundraiserDevice.guid}>{x.fundraiserDevice.fundraiser.name}</Link>
                            </div>
                        </li>
                    )
                })}
            </ul>
            {currentFundraiserDevice && currentFundraiserDevice.optin !== null && (
                <div className="reminder-status-container" style={{ border: '1px solid #ccc', padding: '10px', marginTop: '20px', marginBottom: '20px', borderRadius: '5px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <span>
                        Reminders: {currentFundraiserDevice.isOptedIn ? "On" : "Off"}
                    </span>
                    <button
                        className="btn btn-secondary"
                        onClick={() => setShowOptInModal(true)}
                    >
                        Change
                    </button>
                </div>
            )}
            <OptInModal
                modalStatus={showOptInModal}
                deactivate={() => closeModalUpdateDevice(false)}
                fundraiserDevice={currentFundraiserDevice}
            />
        </div>
    );

}
