// UserTicketManager.js
import React, { useEffect, useState } from 'react';
import TicketsService from "../services/TicketsService";
import { useParams } from "react-router-dom";
import TicketPurchase from '../components/TicketPurchase';

export default function UserTicketManager() {
    const { ticketPurchaseGuid } = useParams();
    const [ticketPurchases, setTicketPurchases] = useState([]);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [orderId, setOrderId] = useState('');

    useEffect(() => {
        TicketsService.getTicketPurchases(ticketPurchaseGuid).then(x => {
            setTicketPurchases(x);
            setOrderId(x[0].orderId);
            setLoading(false);
        }).catch(err => {
            setError(true);
            setLoading(false);
        });
    }, []);

    if (error) {
        return (
            <div>
                Sorry, there are no digital items associated located here :/
            </div>
        )
    }

    if (loading) {
        return (
            <div>Loading…</div>
        )
    }

    return (
        <div className="container py-5">
            <div className="text-center mb-5">
                <h1 className="display-4">Your Ticket Purchases</h1>
                <p className="lead">Order ID: {orderId === 0 ? "Complimentary" : orderId}</p>
                <p className="mb-4">Below are the tickets you've purchased. You can copy the ticket link or send it to a phone number.</p>
            </div>
            {ticketPurchases.map((tp, index) => (
                <div className="card shadow-lg p-3 mb-5 bg-white rounded" key={tp.guid}>
                    <div className="card-body">
                        <h5 className="card-title">Ticket {index + 1} of {ticketPurchases.length}</h5>
                        <TicketPurchase tp={tp} />
                    </div>
                </div>
            ))}
        </div>

    );
}
