import React, { Component } from 'react';

const FundraisersService = {

    //location: null,
    school: [],
    team: null,

    async getStates(itemId) {
        const response = await fetch('api/Fundraisers/ListActiveStates?itemId=' + itemId);
        const data = await response.json();
        return data;
        //this.setState({ forecasts: data, loading: false });
    },

    async getSchools(location, itemId) {
        const response = await fetch('api/Fundraisers/ListActiveSchools?location=' + location + '&itemId=' + itemId);
        const data = await response.json();
        return data;
        //this.setState({ forecasts: data, loading: false });
    },

    async getSchool(schoolId) {
        const response = await fetch('api/Fundraisers/GetSchool?schoolId=' + schoolId);
        const data = await response.json();
        return data;
        //this.setState({ forecasts: data, loading: false });
    },

    async getFundraiserDevice(phone) {
        const response = await fetch('api/Fundraisers/GetFundraiserDevice?phone=' + phone);
        const data = await response.json();
        return data;
        //this.setState({ forecasts: data, loading: false });
    },

    // async getTeams(schoolId) {
    //     const response = await fetch('api/Fundraisers/ListActiveTeams?schoolId=' + schoolId);
    //     const data = await response.json();
    //     return data;
    //     //this.setState({ forecasts: data, loading: false });
    // },
    async getFundraisers(schoolId, itemId) {
        const response = await fetch('api/Fundraisers/ListActiveFundraisers?schoolId=' + schoolId + '&itemId=' + itemId);
        const data = await response.json();
        return data;
        //this.setState({ forecasts: data, loading: false });
    },

    async getFundraiserDetails(fundraiserId) {
        const response = await fetch('api/Fundraisers/GetFundraiserDetails?fundraiserId=' + fundraiserId);
        const data = await response.json();
        return data;
    },
    async getFundraiserDetailsForDevice(fdid) {
        const response = await fetch('api/Fundraisers/GetFundraiserDetailsForDevice?fdid=' + fdid);
        const data = await response.json();
        return data;
    },

    async getFundraisersByPhone(phone) {
        const response = await fetch('api/Fundraisers/ListActiveFundraisers?phone=' + phone);
        const data = await response.json();
        return data;
        //this.setState({ forecasts: data, loading: false });
    },

    async getOrders(fdid) {
        const response = await fetch('api/Fundraisers/ListOrders?phone=' + fdid);
        const data = await response.json();
        return data;
        //this.setState({ forecasts: data, loading: false });
    }
}

export default FundraisersService;