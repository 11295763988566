import { useLocation } from 'react-router-dom';
import React, { useEffect } from "react";

export default function TicketUserBottomNav({ ticketPurchaseSessionGuid, ticketPurchaseGuid, primaryColor, secondaryColor, logoUrl }) {
    const location = useLocation();
    const otherPurchases = (
        <div className="justify-content-center">
            <div className="mx-auto text-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-card-list" viewBox="0 0 16 16">
                    <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                    <path d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                </svg>
            </div>
            <p className="text-right">Purchases</p>
        </div>
        )
    const clipboard = (
        <div className="justify-content-center">
            <div className="mx-auto text-center">
                {logoUrl ? (
                    <img
                        src={logoUrl}
                        alt="Logo"
                        style={{
                            maxHeight: "35px",
                            maxWidth: "35px",
                            objectFit: "contain"
                        }}
                    />
                ) : (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        fill="currentColor"
                        className="bi bi-clipboard2-data-fill"
                        viewBox="0 0 16 16"
                    >
                        <path d="M10 .5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5.5.5 0 0 1-.5.5.5.5 0 0 0-.5.5V2a.5.5 0 0 0 .5.5h5A.5.5 0 0 0 11 2v-.5a.5.5 0 0 0-.5-.5.5.5 0 0 1-.5-.5Z" />
                        <path d="M4.085 1H3.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1h-.585c.055.156.085.325.085.5V2a1.5 1.5 0 0 1-1.5 1.5h-5A1.5 1.5 0 0 1 4 2v-.5c0-.175.03-.344.085-.5ZM10 7a1 1 0 1 1 2 0v5a1 1 0 1 1-2 0V7Zm-6 4a1 1 0 1 1 2 0v1a1 1 0 1 1-2 0v-1Zm4-3a1 1 0 0 1 1 1v3a1 1 0 1 1-2 0V9a1 1 0 0 1 1-1Z" />
                    </svg>
                )}
            </div>
            <p className="text-right">Team</p>
        </div>
    );
    const home =
        <div className="justify-content-center">
            <div className="mx-auto text-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="ml-auto bi bi-house" viewBox="0 0 16 16">
                    <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5Z" />
                </svg>
            </div>
            <p className="text-right">Home</p>
        </div>
    const offers =
        <div className="justify-content-center">
            <div className="mx-auto text-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="ml-auto bi bi-cash-coin" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z" />
                    <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z" />
                    <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z" />
                    <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z" />
                </svg>
            </div>
            <p className="text-right">Offers</p>
        </div>;
    const links = [
        { Name: clipboard, URL: `/MyTeam/${ticketPurchaseSessionGuid}/` },
        { Name: home, URL: `/MyTicket/${ticketPurchaseSessionGuid}/` },
        { Name: offers, URL: `/MyOffers/${ticketPurchaseSessionGuid}/` }
    ];

    if (ticketPurchaseGuid) {
        links.unshift({ Name: otherPurchases, URL: `/ManageDiscounts/${ticketPurchaseGuid}/` });
    }

    const setNavRef = (node) => {
        if (node) {
            const parentContainer = findParentContainer(node);
            if (parentContainer) {
                const childElement = parentContainer.firstElementChild;
                if (childElement) {
                }
                parentContainer.style.paddingLeft = "0";
                parentContainer.style.paddingRight = "0";

                // Assuming the direct child element is the first child of the parent container
                
            }
        }
    };


    const findParentContainer = (element) => {
        if (!element) return null;
        if (element.classList.contains('container')) return element;
        return findParentContainer(element.parentNode);
    };

    return (
        <nav
            ref={setNavRef}
            className={`navbar fixed-bottom navbar-dark justify-content-center ${primaryColor ? '' : 'bg-primary'}`}
            style={{
                margin: "0 !important",
                paddingBottom: 0,
                backgroundColor: primaryColor || ''
            }}
        >
            {links.map((link, index) => {
                return (
                    <a
                        key={index}
                        className="m-auto pt-20"
                        style={{
                            fontSize: 18,
                            color: location.pathname.toLowerCase().startsWith(link.URL.toString().toLowerCase())
                                ? (secondaryColor ? secondaryColor : '#FFFFFF')
                                : '#4F4F4F'
                        }}
                        href={link.URL}
                    >
                        {link.Name}
                    </a>
                );
            })}
        </nav>

    );
}