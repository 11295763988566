import React, { Component, useEffect, useState } from "react";
import FundraisersService from "../services/FundraisersService";
import { Link, useParams } from "react-router-dom";

export default function SelectLocation(props) {
  //useParams
  const { pItemId } = useParams();
  const { pPhone } = useParams();
  //useState
  const [itemId, setItemId] = useState("0");
  const [phone, setPhone] = useState("0");
  const [states, setStates] = useState();

  useEffect((x) => {
    let temp_ItemId = "0";
    let temp_Phone = "0";
    if (props.product) {
      switch (props.product) {
        case "donate":
          temp_ItemId = "-1";
          break;
        case "give":
          temp_ItemId = "-2";
          break;
      }
    } else {
      if (pItemId) {
        temp_ItemId = pItemId;
      }
    }

    if (pPhone) temp_Phone = pPhone;

    FundraisersService.getStates(temp_ItemId).then((x) => setStates(x));

    //commit temp vars to state
    setItemId(temp_ItemId);
    setPhone(temp_Phone);
  }, []);

  return (
    <div>
      <h2>Select Fundraiser's State</h2>
      <ul className="list-group">
        {states &&
          states.map((x, index) => {
            return (
              <Link
                key={index}
                className="list-group-item list-group-item-action"
                to={"/SelectSchool/" + x + "/" + itemId + "/" + phone}
              >
                {x}
              </Link>
            );
          })}
      </ul>
    </div>
  );
}
