import React, {useEffect, useState } from 'react';
import FundraisersService from "../services/FundraisersService";
import { Link, useParams } from 'react-router-dom';
import Breadcrumb from '../components/BreadcrumbComponent';

export default function SelectState() {
    const {schoolId} = useParams();
    const {itemId} = useParams();
    const {phone} = useParams();
    const [fundraisers, setFundraisers] = useState();
    const [school, setSchool] = useState();

    useEffect(x => {
        FundraisersService.getSchool(schoolId).then(x => {
                setSchool(x);
            });
        FundraisersService.getFundraisers(schoolId, itemId).then(x => setFundraisers(x)); /*cannot connect the get request to FunraiserController GetFundraisers */
    }, []);

    return (
        <div>
            <h2>Select Group</h2>
            <Breadcrumb location={school && school.state} school={school && school.name}/>
            <ul className="list-group">
                {fundraisers && fundraisers.map((x, index) => {
                    return (<Link key={index} className="list-group-item list-group-item-action" to={"/Order/" + x.id + "/" + itemId + "/" + phone}>{x.teamName}</Link>)
                })}
            </ul>
        </div>
    );

}
