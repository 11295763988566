function GiftFormSelect(props) {
    return (
        <div>
            <select className="form-select" id="donationAmount" onChange={event => props.calculateDonation(event)}>
                <option value="0">Please Select</option>
                {props.choices && props.choices.map((value, index) => {
                    return (
                        <option key={index} value={value}>{value}</option>)
                })}
                <option value="-1">Custom</option>
            </select>
        </div>
    )
}

function DonationForm(props) {
    const isCustom = props.isCustom;
    const customDonation = props.customDonation;
    const setIsCustom = props.setIsCustom;
    const setCustomDonation = props.setCustomDonation;
    function checkCustom(position) {
        if (position == -1)
            setIsCustom(true);
        else
            setIsCustom(false);
    };
    const donationValues = [1000, 500, 250, 150, 125, 100, 75, 50, 35];
    const giftValues = [10000, 7500, 5000, 2500, 1500, 1000, 500];

    function calculateDonation(event) {
        checkCustom(event.target.value);
        getDonationValue(event.target.value);
        props.updateTotal();
    }
    const getDonationValue = (input) => {
        props.order.donation = input == -1 ? customDonation : input;
        console.log(props.order.donation);
        props.updateTotal();
    }
    const changeCustom = (input) => {
        setCustomDonation(input);
        props.order.donation = input;
        console.log(props.order.donation);
        props.updateTotal();

    }
    return (
        <>
            <div className={props.isDonate ? "col-sm-6" : "d-none"}>
                <label htmlFor="donationAmount" className="form-label">Donation Amount</label>
                <GiftFormSelect calculateDonation={(e) => calculateDonation(e)} choices={!props.isDonate ? giftValues : donationValues} />
                <div className={isCustom ? "py-3" : "d-none"}>
                    <input type="text" className="form-control" id="customDonationAmount" placeholder="Must be at least $35.00." value={customDonation} type="number" min={isCustom ? 35 : 0}
                        required={isCustom}
                        onChange={event => changeCustom(event.target.value)}
                    />
                    <div className="invalid-feedback"> Donation amount of at least $35.00 required. </div>
                </div>
            </div>
        </>
    )
}
export default DonationForm;