import React, { Component, useEffect, useState, useRef } from 'react';
import axios from 'axios';

import { useParams } from 'react-router-dom';
import OrdersService from '../services/OrdersService';
import { Input, Label, FormGroup } from 'reactstrap';
import BillingAddressForm from '../components/BillingAddressForm';
import DeliveryAddressForm from '../components/DeliveryAddressForm';
import OrderedTable from '../components/OrderedTable';

export default function Order(props) {
    const [purchaseId, setPurchaseId] = useState(11);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [ccnumberError, setnumberError] = useState();
    const [ccexpError, setexpError] = useState();
    const [cccvcError, setccvcError] = useState();
    const { orderId } = useParams()
    const [order, setOrder] = useState();
    const [sameAddress, setSameAddress] = useState(true);
    const orderRef = useRef();

    const [paymentMethod, setPaymentMethod] = useState('1'); //1=cash
    const [checkNumber, setCheckNumber] = useState();

    const handlePaymentMethodChange = (event) => {
        setPaymentMethod(event.target.value);
    };

    const handleCheckNumberChange = (event) => {
        setCheckNumber(event.target.value);
    };

    const handleSubmit = (event) => {
        enforceValidation();
        event.preventDefault();
        setIsSubmitting(true);
        //console.log(window.CollectJS.startPaymentRequest());

        saveOrderDetails(event);
        saveBillingDetails(event);
        orderRef.current = order;

        finishSubmit();
    };

    const enforceValidation = () => {
        const forms = document.querySelectorAll('.needs-validation')  // Loop over them and prevent submission
        Array.from(forms).forEach(form => {
            form.addEventListener('submit', event => {
                if (!form.checkValidity()) {
                    event.preventDefault()
                    event.stopPropagation()
                    //$(".is-invalid:first").focus();
                    window.scrollTo(0, 0);
                }
                form.classList.add('was-validated')
            }, false)
        });
    };

    const saveOrderDetails = (event) => {
        if (sameAddress) {
            order.supporterFirstName = event.target.firstName.value;
            order.supporterLastName = event.target.lastName.value;
            order.shippingAddress = event.target.address.value;
            order.shippingAddress2 = event.target.address2.value;
            order.shippingCity = event.target.city.value;
            order.shippingState = event.target.state.value;
            order.shippingZip = event.target.zip.value;
        } else {
            order.supporterFirstName = event.target.deliveryfirstName.value;
            order.supporterLastName = event.target.deliverylastName.value;
            order.shippingAddress = event.target.deliveryaddress.value;
            order.shippingAddress2 = event.target.deliveryaddress2.value;
            order.shippingCity = event.target.deliverycity.value;
            order.shippingState = event.target.deliverystate.value;
            order.shippingZip = event.target.deliveryzip.value;
        }
        order.supporterPhoneNumber = event.target.phone.value;
        order.donorEmail = event.target.email.value;
    }

    const saveBillingDetails = (event) => {
        order.payment.amount = order.orderTotal;
        order.payment.firstName = event.target.firstName.value;
        order.payment.lastName = event.target.lastName.value;
        order.payment.address1 = event.target.address.value;
        order.payment.address2 = event.target.address2.value;
        order.payment.city = event.target.city.value;
        order.payment.state = event.target.state.value;
        order.payment.zip = event.target.zip.value;
    }

    const finishSubmit = (response) => {

        //orderRef.current.payment.token = response.token;
        orderRef.current.paymentTypeId = paymentMethod;
        orderRef.current.checkNumber = checkNumber;
        OrdersService.ProcessOrderCashCheck(orderRef.current).then(res => {
            console.log(res.data);
            //console.log(res.data);
            if (res.data === 'success') {
                axios.post(orderRef.current.appSiteUrl + '/api/donor/digitalReceipt/' + orderRef.current.orderId).then(retUrl => {
                    console.log(retUrl);
                    window.location.href = retUrl.data;
                })
            }
            else {
                alert(res.data);
                setIsSubmitting(false);
            }

        });
        //setIsSubmitting(false);
        //this.setState({ isSubmitting: false, alertMessage: 'The form was submitted. Check the console to see the output data.' });
    };

    useEffect(x => {



        OrdersService.getFundraiserOrder(orderId).then((x) => {
            setOrder(x);
            console.log(x);




        });
        enforceValidation();


        // setTimeout(() => {
        //    
        // }, 100);


        return () => {

        };


    }, []);
    return (
        <>
            <h2>Checkout{(order && order.testMode ? " *TEST_MODE*" : "")}</h2>

            <div className="row g-5">
                <div className="col-md-7 col-lg-8">
                    <form className="needs-validation" noValidate onSubmit={handleSubmit}>
                        <h4 className="mb-3">Billing Address</h4>
                        <BillingAddressForm />
                        <hr className="my-4" />
                        <div className="mb-3">
                            <h4 className="mb-3">Delivery Address</h4>
                            <FormGroup check className="mb-3">
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        defaultChecked={sameAddress}
                                        onChange={
                                            (e) => {
                                                setSameAddress(e.target.checked);
                                            }
                                        }
                                    />{""}
                                    <strong>Same as Billing Address</strong>
                                </Label>
                            </FormGroup>
                            <DeliveryAddressForm show={!sameAddress} />
                        </div>
                        <hr className="my-4" />


                        <h4>Payment</h4>
                        <div className="d-block my-3">
                            <div className="custom-control custom-radio">
                                <input id="cash"
                                    name="paymentMethod"
                                    value="1"
                                    type="radio"
                                    className="custom-control-input"
                                    checked={paymentMethod === '1'}
                                    onChange={handlePaymentMethodChange}

                                    required="" />
                                <label className="custom-control-label" htmlFor="cash">Cash</label>
                            </div>
                            <div className="custom-control custom-radio">
                                <input id="check"
                                    name="paymentMethod"
                                    value="3"
                                    type="radio"
                                    className="custom-control-input"
                                    checked={paymentMethod === '3'}
                                    onChange={handlePaymentMethodChange}
                                    required="" />
                                <label className="custom-control-label" htmlFor="check">Check</label>
                            </div>
                        </div>



                        <div className="d-block my-3">
                            {paymentMethod === '3' && (
                                <>
                                    <label htmlFor="checkNum" className="form-label">Check Num: </label>
                                    <input
                                        id="checkNum"
                                        type="text"
                                        value={checkNumber}
                                        onChange={handleCheckNumberChange}
                                        className="form-control"
                                        required
                                    />
                                    <div className="invalid-feedback">
                                        Please enter a check number.</div>
                                </>
                            )}

                        </div>


                        <hr className="my-4" />
                        <h4>Order Summary</h4>
                        <OrderedTable order={order} paymentType={paymentMethod} />
                        <hr className="my-4" />
                        <button
                            className="w-100 btn btn-primary btn-lg" type="submit" disabled={isSubmitting}>Submit
                        </button>
                    </form>
                </div>
            </div>
        </>
    );

}
