import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
export const useWebAppManifest = () => {
    const location = useLocation();
    useEffect(() => {
        const staticManifestLinks = document.querySelectorAll('link[rel="manifest"]');
        staticManifestLinks.forEach((link) => {
            document.head.removeChild(link);
        });

        const manifestLink = document.createElement('link');
        manifestLink.rel = 'manifest';
        manifestLink.href = `/api/Tickets/GetManifest?start_url=${encodeURIComponent(location.pathname)}&rand=${Math.random()}`;
        document.head.appendChild(manifestLink);

        // Cleanup function to remove the link when the component is unmounted or the URL changes
        return () => {
            document.head.removeChild(manifestLink);
            staticManifestLinks.forEach((link) => {
                document.head.appendChild(link);
            });
        };
    }, [location.pathname]); // This hook will run every time the URL changes
};
