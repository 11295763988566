import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AdminService from "../services/AdminService";

const RedirectRoute = ({ to }) => {
    const params = useParams();


    const joinUrls = (base, path) => {
        const normalizedBase = base.endsWith("/") ? base.slice(0, -1) : base;
        const normalizedPath = path.startsWith("/") ? path : "/" + path;
        return normalizedBase + normalizedPath;
    };

    useEffect(() => {
        const fetchBaseURL = async () => {
            const res = await AdminService.getBaseUrl();
            const baseURL = res.url;// Assuming this is your method to fetch the base URL from the controller
            const url = typeof to === 'function' ? to(params) : to;
            window.location.href = joinUrls(baseURL, url);
        };

        fetchBaseURL();
    }, [to, params]);

    return null;
};

export default RedirectRoute;
