import TicketsService from '../services/TicketsService';

const checkSession = async (sessionGuid, callbackFetch) => {
    const currentSession = await getCurrentSession(sessionGuid);
    const applySecurity = await checkForSecurity();
    if (applySecurity) {
        const isValidSession = await redirectInactive(currentSession);
        if (isValidSession) {
            callbackFetch(currentSession);
        }
    }
    else {
        callbackFetch(currentSession);
    }
};


const getCurrentSession = async (paramsGuid) => {
    var response = await TicketsService.getSession(paramsGuid);
    if (response.guid) {
        if (response.guid != paramsGuid) {
            const path = window.location.pathname;
            const newPath = path.substring(0, path.lastIndexOf('/') + 1) + response.guid;
            window.location.href = newPath;
        } else if (response.isTicket) {
            return response.guid;
        } else if (response.isSession) {
            return response.guid;
        }
    }
};
const checkForSecurity = async () => {
    var response = await TicketsService.getSecurityStatus();
    if (response) {
        if (response.allowAll) {
            return false;
        }
        return true;
    }
    return false;
};

const redirectInactive = async (ticketPurchaseSessionGuid) => {
    const res = await TicketsService.checkSession(ticketPurchaseSessionGuid);
    if (res.success) {
        if (res.sample) {
            return true;
        }
        if (res.redirect && res.inactive) {
            window.location.href = res.redirect;
            return false;
        }
        else {
            return true;
        }
    }
    return false;
};


export { checkSession, getCurrentSession, redirectInactive };
