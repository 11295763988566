export const extractTeamStyling = (team) => {
    const primaryColor = team.primaryColorHex ? "#" + team.primaryColorHex : null;
    const secondaryColor = team.secondaryColorHex ? "#" + team.secondaryColorHex : null;
    const logoUrl = team.photoUrl ? "https://fundraising-u-production.s3.amazonaws.com/" + team.photoUrl : null;
    const useBackgroundImage = team.useBackgroundPhoto;
    const backgroundImage = useBackgroundImage && team.backgroundPhotoUrl ? "https://fundraising-u-production.s3.amazonaws.com/" + team.backgroundPhotoUrl : null;

    return { primaryColor, secondaryColor, logoUrl, useBackgroundImage, backgroundImage };
};

export const getTicketUserBottomNavProps = (team) => {
    const primaryColor = team.primaryColorHex ? "#" + team.primaryColorHex : null;
    const secondaryColor = team.secondaryColorHex ? "#" + team.secondaryColorHex : null;
    const logoUrl = team.photoUrl ? "https://fundraising-u-production.s3.amazonaws.com/" + team.photoUrl : null;

    return { primaryColor,secondaryColor, logoUrl };
};

export const getBackgroundStyle = (team) => {
    console.log(extractTeamStyling(team));
    const { useBackgroundImage, backgroundImage, secondaryColor } = extractTeamStyling(team);
    return useBackgroundImage
        ? {
            backgroundImage: `url(${backgroundImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        }
        : {
            backgroundColor: secondaryColor,
        };
};