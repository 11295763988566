import SelectLocation from "./pages/SelectLocation";
import SelectSchool from "./pages/SelectSchool";
import SelectTeam from "./pages/SelectTeam";
import Order from "./pages/Order";
import Checkout from "./pages/Checkout";
import CheckoutCashCheck from "./pages/CheckoutCashCheck";
import TicketRedemption from "./pages/TicketRedemption";
import Fundraiser from "./pages/Fundraiser";
import FundraiserOrders from "./pages/FundraiserOrders";
import Fundraisers from "./pages/Fundraisers";
import UserOffers from "./pages/UserOffers";
import UserDiscountManager from "./pages/UserDiscountManager";
import UserTeam from "./pages/UserTeam";
import UserTicket from "./pages/UserTicket";
import Team from "./pages/Team";
import UserRequestLink from "./pages/UserRequestLink";
import UserRequestLinkConfirmation from "./pages/UserRequestLinkConfirmation";
import UserLinkRedemption from "./pages/UserLinkRedemption";
import RedirectRoute from './services/RedirectRoute';

const AppRoutes = [
    {
        index: true,
        element: <SelectLocation />
    },
    {
        path: '/SelectLocation',
        element: <SelectLocation />
    },
    {
        path: '/Give',
        element: <SelectLocation product={"give"} />
    },
    {
        path: '/Donate',
        element: <SelectLocation product={"donate"} />
    },
    {
        path: '/Purchase/:pItemId/:pPhone',
        element: <SelectLocation />
    },
    {
        path: '/SelectSchool/:location/:itemId/:phone',
        element: <SelectSchool />
    },
    {
        path: '/SelectTeam/:schoolId/:itemId/:phone',
        element: <SelectTeam />
    },
    {
        path: '/Order/:fundraiserId/:itemId/:phone',
        element: <Order />
    }
    ,
    {
        path: '/Order/:fdid',
        element: <Order />
    },
    {
        path: '/Order/:fdid/:cashcheck',
        element: <Order />
    },
    {
        path: '/Order/Edit/:orderId',
        element: <Order />
    },

    {
        path: '/Fundraisers/:fdid',
        element: <Fundraisers />
    },
    {
        path: '/FundraiserOrders/:fdid',
        element: <FundraiserOrders />
    },
    {
        path: '/Fundraiser/:fundraiserDeviceGuid',
        element: <Fundraiser />
    },
    {
        path: '/Checkout/:orderId',
        element: <Checkout />
    },
    {
        path: '/CheckoutCashCheck/:orderId/:cashcheck',
        element: <CheckoutCashCheck />
    },
    {
        path: '/MyOffers/:ticketPurchaseSessionGuid',
        element: <UserOffers />
    },
    {
        path: '/ReactivateTicket/:ticketPurchaseSessionGuid',
        element: <UserRequestLink />
    },
    {
        path: '/ActivateTicket/:ticketPurchaseSessionGuid',
        element: <UserRequestLink />
    },
    {
        path: '/ActivateTicket/:ticketPurchaseSessionGuid/Confirmation/:phoneNumber',
        element: <UserRequestLinkConfirmation />
    },
    {
        path: '/ActivateTicket/:ticketPurchaseSessionGuid/Confirmation/',
        element: <UserRequestLinkConfirmation />
    },
    {
        path: '/ActivateTicket/:ticketPurchaseSessionGuid/RegisterDevice/:phoneNumber',
        element: <UserLinkRedemption />
    },
    {
        path: '/ActivateTicket/:ticketPurchaseSessionGuid/RegisterDevice/',
        element: <UserLinkRedemption />
    },
    {
        path: '/MyTicket/:ticketPurchaseSessionGuid',
        element: <UserTicket />
    },
    {
        path: '/MyTeam/:ticketPurchaseSessionGuid',
        element: <UserTeam />
    },
    {
        path: '/ManageDiscounts/:ticketPurchaseGuid',
        element: <UserDiscountManager />
    },

    {
        path: '/MyOffers/:ticketPurchaseSessionGuid/Redeem/:ticketOfferId',
        element: <TicketRedemption />
    },
    {
        path: '/TicketPreview/:fundraiserId/',
        element: <UserOffers />
    },
    {
        path: '/Team/:ticketId',
        element: <Team />
    },
    {
        path: '/Admin',
        element: <RedirectRoute to={(params) => `/`} />
    },
    {
        path: '/Admin/Vendors',
        element: <RedirectRoute to={(params) => `/Vendors/`} />
    },
    {
        path: '/Admin/Vendor/:vendorId',
        element: <RedirectRoute to={(params) => `/Vendors/Details/${params.vendorId}`} />
    },
    {
        path: '/Admin/VendorOffers',
        element: <RedirectRoute to={(params) => `/VendorOffers/`} />
    },
    {
        path: '/Admin/VendorOffer/:vendorOfferId/:vendorId',
        element: <RedirectRoute to={(params) => `/VendorOffers/Details/${params.vendorOfferId}`} />
    },
    {
        path: '/Admin/Ticket/:fundraiserId',
        element: <RedirectRoute to={(params) => `/Fundraisers/Details/${params.fundraiserId}`} />
    },
    {
        path: '/Admin/Tickets',
        element: <RedirectRoute to={(params) => `/Fundraisers/`} />
    }
];

export default AppRoutes;
