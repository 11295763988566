import React, { useEffect, useRef } from 'react';

export default function Collage(props) {
    const containerRef = useRef(null);

    useEffect(() => {
        props.images.forEach(image => {
            const imgElement = new Image();
            imgElement.src = image;
            imgElement.onload = function () {
                const { naturalWidth, naturalHeight } = this;
                const maxWidth = window.innerWidth * 0.9; // limit image width to 80% of window width
                const scaleFactor = maxWidth / naturalWidth;
                const width = naturalWidth * scaleFactor;
                const height = naturalHeight * scaleFactor;
                imgElement.style.width = width + "px";
                imgElement.style.height = height + "px";
                imgElement.style.alignContent = "center";
                imgElement.style.margin = "auto";
                containerRef.current.appendChild(imgElement);
            };
        });
    }, [props.images]);

    return (
        <div ref={containerRef} style={{ display: "flex", flexWrap: "wrap" }}>
        </div>
    );
};
