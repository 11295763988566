import axios from "axios";

const TicketsService = {
    async getTeam(teamId) {
        const response = await fetch("api/Tickets/GetTeam?teamId=" + teamId);
        const data = await response.json();
        return data;
        //this.setState({ forecasts: data, loading: false });
    },
    async getTicketFundraiser(itemsOptionId) {
        const response = await fetch(
            "api/Tickets/GetTicketFundraiser?itemsOptionId=" + itemsOptionId
        );
        const data = await response.json();
        return data;
        //this.setState({ forecasts: data, loading: false });
    },
    async getTicket(ticketPurchaseSessionGuid) {
        const response = await fetch(
            "api/Tickets/GetTicket?ticketPurchaseSessionGuid=" + ticketPurchaseSessionGuid
        );
        const data = await response.json();
        return data;
        //this.setState({ forecasts: data, loading: false });
    },
    async getTicketPurchases(ticketPurchaseGuid) {
        const response = await fetch("api/Tickets/GetTicketPurchases?ticketPurchaseGuid=" + ticketPurchaseGuid);
        const data = await response.json();
        return data;
    },
    async shareTicketPurchase(ticketPurchaseGuid, phoneNumber) {
        const response = await fetch(
            "api/Tickets/ShareTicketPurchase?ticketPurchaseGuid=" + ticketPurchaseGuid + "&phoneNumber=" + phoneNumber
        );
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;
    },
    async getFundraisersTicket(fundraiserId) {
        const response = await fetch(
            "api/Tickets/GetFundraisersTicket?fundraiserId=" + fundraiserId
        );
        const data = await response.json();
        return data;
        //this.setState({ forecasts: data, loading: false });
    },
    async addPhone(phoneNumber, ticketPurchaseSessionGuid) {
        try {
            const response = await fetch(`api/Tickets/CreateAccessLink?phoneNumber=${phoneNumber}&ticketPurchaseSessionGuid=${ticketPurchaseSessionGuid}`);
            if (!response.ok) {
                throw new Error(`${response.statusText}`);
            }
            const data = await response.json();
            if (data.redirect) {
                return { success: true, redirect: `/ActivateTicket/${ticketPurchaseSessionGuid}/Confirmation/${phoneNumber}` };
            } else {
                return { success: true };
            }
        } catch (error) {
            return { success: false };
        }
    },
    async redeemLink(ticketPurchaseSessionGuid) {
        const response = await fetch(`api/Tickets/RedeemActivationLink?ticketPurchaseSessionGuid=${ticketPurchaseSessionGuid}`);
        if (!response.ok) {
            throw new Error(`${response.statusText}`);
        }
        const data = await response.json();
        return data;
    },

    async checkSession(ticketPurchaseSessionGuid) {
        const response = await fetch(`api/Tickets/CheckSessionStatus?ticketPurchaseSessionGuid=${ticketPurchaseSessionGuid}`);
        const data = await response.json();
        return data;
    },

    async getSecurityStatus() {
        const response = await fetch(`api/Tickets/CheckSecurityStatus`);
        const data = await response.json();
        return data;
    },
    async getSession(ticketPurchaseSessionGuid) {
        const response = await fetch(`api/Tickets/GetSession?ticketPurchaseSessionGuid=${ticketPurchaseSessionGuid}`);
        if (!response.ok) {
            throw new Error(`${response.statusText}`);
        }
        const data = await response.json();
        return data;
    },

    async getOffers(ticketPurchaseSessionGuid) {
        const response = await fetch(
            "api/Tickets/GetOffers?ticketPurchaseSessionGuid=" + ticketPurchaseSessionGuid
        );
        const data = await response.json();
        return data;
        //this.setState({ forecasts: data, loading: false });
    },
    async getTicketOfferDetails(ticketPurchaseSessionGuid, ticketOfferId) {
        const response = await fetch(
            `api/Tickets/GetTicketOfferDetails?ticketPurchaseSessionGuid=${ticketPurchaseSessionGuid}&ticketOfferId=${ticketOfferId}`
        );
        const data = await response.json();
        return data;
        //this.setState({ forecasts: data, loading: false });
    },
    async redeemTicketPurchaseOffer(ticketPurchaseSessionGuid, ticketOfferId) {
        return axios.post(
            `api/Tickets/RedeemTicketPurchaseOffer?ticketPurchaseSessionGuid=${ticketPurchaseSessionGuid}&ticketOfferId=${ticketOfferId}`
        );
    },
};

export default TicketsService;
