import { useParams } from 'react-router-dom';

export default function UserRequestLinkConfirmation() {
    const { phoneNumber } = useParams();
    const formattedPhoneNumber = `(${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(3, 6)}-${phoneNumber.substring(6)}`;
    return (
        <div style={{ height: "100vh" }}>
            <h2 style={{ textAlign: "center" }}>Confirmation</h2>
            <p style={{ fontSize: "18px", textAlign: "center" }}>
                The phone number registered to this ticket it will receive a link to access the ticket.
            </p>
        </div>
    );
}
