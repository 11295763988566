import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import TicketsService from "../services/TicketsService";

export default function UserRequestLink()
{
    const { ticketPurchaseSessionGuid } = useParams();
    const [phoneNumber, setPhoneNumber] = useState('');
    const [validNumber, setValidNumber] = useState(false); // added state for input validation
    const [validRequest, setValidRequest] = useState(false); // added state for input validation

    const redirectIfActive = async (ticketPurchaseSessionGuid) => {
            const res = await TicketsService.checkSession(ticketPurchaseSessionGuid);
        if (res.success)
        {
            console.log(res);
            if (res.redirect && res.active || res.sample) {
                window.location.href = res.redirect;
            }
            else
            {
                setValidRequest(true);
            }
        }
        else
        {
            setValidRequest(false);
        }
    };
    const handlePhoneNumberChange = (e) => {
        const inputValue = e.target.value.replace(/[^\d\s()-]/g, ''); // remove all non-digit, non-space, non-parentheses, and non-dash characters
        setPhoneNumber(inputValue);
        setValidNumber(inputValue.replace(/[^\d]/g, '').length === 10); // validate that the input contains exactly 10 digits
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const phoneNumberDigits = phoneNumber.replace(/[^0-9]/g, ''); // remove all non-digit characters from the phone number input
        TicketsService.addPhone(phoneNumberDigits, ticketPurchaseSessionGuid).then(res => {
            if (res.success) {
                if (res.redirect) {
                    window.location.href = res.redirect;
                }
            }
        }).catch(error => {
        });
    }

    useEffect(() => {
        redirectIfActive(ticketPurchaseSessionGuid);
    }, []);
    if (!validRequest)
        return null;
    return (
        <div style={{ height: "100vh" }}>
            <form onSubmit={handleSubmit}>
                <p>
                    Please note that for security purposes, each ticket can only be registered
                    to one device. Enter the phone number you would like to use to register for
                    this ticket below. If a phone number is already registered, the link will
                    be sent to that number.
                </p>
                <label htmlFor="phoneNumber">Phone Number:</label>
                <input
                    type="tel" // use tel type for phone numbers
                    id="phoneNumber"
                    name="phoneNumber"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    required
                />
                {!validNumber && (
                    <p style={{ color: "red" }}>
                        Please enter a valid 10-digit phone number.
                    </p>
                )}
                <input type="submit" value="Submit" disabled={!validNumber} />
            </form>
        </div>

    );
}
