import { useNavigate } from 'react-router-dom';


export default function Breadcrumb(props) {
    const navigate = useNavigate();
    return (
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li type="button" className={"breadcrumb-item active"} onClick={props.school != null ? () => navigate(-2) : () => navigate(-1)}>{props.location}</li>
                <li type="button" className={props.school != null ? "breadcrumb-item active" : "d-none"} onClick={() => navigate(-1)}>{props.school}</li>
            </ol>
        </nav>
    );
}