import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, ModalBody, Button } from "reactstrap";
import './counter.module.css';
import StudentsService from "../services/StudentsService";

export default function OptInModal(props) {
    const [isLoading, setIsLoading] = useState(false);

    const fundraiserDevice = props.fundraiserDevice;
    if (!fundraiserDevice) {
        return <div>Loading...</div>;
    }

    const modal = props.modalStatus;
    const phone = fundraiserDevice.phone;
    const close = () => { props.deactivate() };


    const updateOptInStatus = (isOptedIn) => {
        setIsLoading(true);
        console.log(phone);
        StudentsService.UpdateOptInStatus({
            phone: phone,
            status: isOptedIn
        }).then(() => {
            setIsLoading(false);
            close();
        });
    };

    return (
        <Modal isOpen={modal} toggle={close}>
            <ModalBody>
                <div className="row d-flex justify-content-center text-center">
                    <h2>Send me reminders to reach out to potential supporters</h2>
                    <div className="mt-3">
                        <Button color="primary" onClick={() => updateOptInStatus(true)} disabled={isLoading}>
                            Yes
                        </Button>
                        {' '}
                        <Button color="secondary" onClick={() => updateOptInStatus(false)} disabled={isLoading}>
                            No
                        </Button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
}
