import axios from "axios";

const OrdersService = {

    location: null,
    school: [],
    team: null,
    fundraiser: null,
    fundraiserDevice: null,

    async CreateOrder(order) {
        return axios.post('api/Orders/CreateOrder', order);
    },
    async TestProcess(token) {
        return axios.post(`api/Orders/TestProcess`, token)
    },
    async ProcessOrder(order) {
        return axios.post(`api/Orders/ProcessOrder`, order)
    },
    async ProcessOrderCashCheck(order) {
        return axios.post(`api/Orders/ProcessOrderCashCheck`, order)
    },

    async NewOrderWithFDID(newOrder) {
        return axios.post('api/Orders/NewOrderWithFDID', newOrder);
    },
    //async NewFundraiserDevice(newFundraiserDevice) {
    //    return axios.post('api/Orders/NewFundraiserDevice', newFundraiserDevice);
    //},
    async NewFundraiserDevice() {
        return axios.post('api/Orders/NewFundraiserDevice');
    },
    async getFundraiserOrder(orderId) {
        const response = await fetch('api/Orders/GetFundraiserOrder?orderId=' + orderId);
        const data = await response.json();
        return data;
    },
    async getOrder(orderId) {
        const response = await fetch('api/Orders/GetOrder?orderId=' + orderId);
        const data = await response.json();
        return data;
        //this.setState({ forecasts: data, loading: false });
    },

    async getFundraisers(phone) {
        const response = await fetch('api/Orders/ListActiveFundraisersByPhone?phone=' + phone);
        const data = await response.json();
        return data;
        //this.setState({ forecasts: data, loading: false });
    },

    async getOrders(fdid) {
        const response = await fetch('api/Orders/ListOrders?fdid=' + fdid);
        const data = await response.json();
        return data;
        //this.setState({ forecasts: data, loading: false });
    },

    async getFundraisersForDevice(fdid) {
        const response = await fetch('api/Orders/GetFundraisersForDevice?fdid=' + fdid);
        const data = await response.json();
        return data;
        //this.setState({ forecasts: data, loading: false });
    },

    //async getFundraisersForDevice(phone) {
    //    const response = await fetch('api/Orders/GetFundraisersForDevice?phone=' + phone);
    //    const data = await response.json();
    //    return data;
    //    //this.setState({ forecasts: data, loading: false });
    //},

    async getMyFundraiser(fundraiserDeviceGuid) {
        const response = await fetch('api/Orders/GetMyFundraiser?fundraiserDeviceGuid=' + fundraiserDeviceGuid);
        console.log(response)
        const data = await response.json();
        return data;
        //this.setState({ forecasts: data, loading: false });
    },

    async getFundraiserDevice(fdid) {
        const response = await fetch('api/Orders/GetFundraiserDevice?fdid=' + fdid);
        const data = await response.json();
        return data;
        //this.setState({ forecasts: data, loading: false });
    },
    async getQrCode(fdid) {
        const response = await fetch('api/Orders/GetQRCode?fdid=' + fdid);
        const data = await response.json();
        return data;
        //this.setState({ forecasts: data, loading: false });
    },
    async getItems(fundraiserId) {
        const response = await fetch('api/Orders/GetItems?fundraiserId=' + fundraiserId);
        const data = await response.json();
        return data;
        //this.setState({ forecasts: data, loading: false });
    }

}

export default OrdersService;