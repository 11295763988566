import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import TicketsService from "../services/TicketsService";

export default function UserLinkRedemption() {
    const { ticketPurchaseSessionGuid } = useParams();
    const [validRequest, setValidRequest] = useState(false); // added state for input validation
    const redirectIfActive = async (ticketPurchaseSessionGuid) => {
        const res = await TicketsService.checkSession(ticketPurchaseSessionGuid);
        if (res.success)
        {
            if (res.redirect && res.active || res.sample)
            {
                    window.location.href = res.redirect;
                    return false;
            }
            else if (res.deactivated)
            {
                setValidRequest(false);
                window.location.href = res.redirect;

            }
            else
            {
                setValidRequest(true);
            }
        }
        else
        {
            setValidRequest(true);
        }

    };

    const handleSubmit = (event) => {
        event.preventDefault();
        TicketsService.redeemLink(ticketPurchaseSessionGuid).then(res => {
            console.log(res);
            if (res.success) {
                if (res.redirect) {
                    window.location.href = res.redirect;
                }
            }
        }).catch(error => {
            console.error(error);
        });
    }
    useEffect(() => {
        redirectIfActive(ticketPurchaseSessionGuid);
    }, []);
    if (validRequest == false)
        return null;
    return (
        <div style={{ height: "100vh" }}>
            <form onSubmit={handleSubmit}>
                <p>
                    Please note that for security purposes, each ticket can only be registered to one device.
                    Upon registration, this device will be bound to this ticket.
                    Are you sure you want to register this device to your ticket? (This device will need to be presented at checkout or wherever the vendor validates the ticket)
                </p>
                <button type="button" onClick={handleSubmit} className="btn btn-success">Register this Device</button>
            </form>
        </div>
    );
}


