import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import OrdersService from '../services/OrdersService';
import { Input, Label, FormGroup } from 'reactstrap';
import BillingAddressForm from '../components/BillingAddressForm';
import DeliveryAddressForm from '../components/DeliveryAddressForm';
import OrderedTable from '../components/OrderedTable';

const stateLookup = {
    'Alabama': 'AL',
    'Alaska': 'AK',
    'Arizona': 'AZ',
    'Arkansas': 'AR',
    'California': 'CA',
    'Colorado': 'CO',
    'Connecticut': 'CT',
    'Delaware': 'DE',
    'District of Columbia': 'DC',
    'Florida': 'FL',
    'Georgia': 'GA',
    'Guam': 'GU',
    'Hawaii': 'HI',
    'Idaho': 'ID',
    'Illinois': 'IL',
    'Indiana': 'IN',
    'Iowa': 'IA',
    'Kansas': 'KS',
    'Kentucky': 'KY',
    'Louisiana': 'LA',
    'Maine': 'ME',
    'Maryland': 'MD',
    'Massachusetts': 'MA',
    'Michigan': 'MI',
    'Minnesota': 'MN',
    'Mississippi': 'MS',
    'Missouri': 'MO',
    'Montana': 'MT',
    'Nebraska': 'NE',
    'Nevada': 'NV',
    'New Hampshire': 'NH',
    'New Jersey': 'NJ',
    'New Mexico': 'NM',
    'New York': 'NY',
    'North Carolina': 'NC',
    'North Dakota': 'ND',
    'Ohio': 'OH',
    'Oklahoma': 'OK',
    'Oregon': 'OR',
    'Pennsylvania': 'PA',
    'Puerto Rico': 'PR',
    'Rhode Island': 'RI',
    'South Carolina': 'SC',
    'South Dakota': 'SD',
    'Tennessee': 'TN',
    'Texas': 'TX',
    'Utah': 'UT',
    'Vermont': 'VT',
    'Virginia': 'VA',
    'Washington': 'WA',
    'West Virginia': 'WV',
    'Wisconsin': 'WI',
    'Wyoming': 'WY'
};

export default function Order(props) {
    const [purchaseId, setPurchaseId] = useState(11);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { orderId } = useParams()
    const [order, setOrder] = useState();
    const [sameAddress, setSameAddress] = useState(true);
    const orderRef = useRef();

    const handleSubmit = (event) => {
        enforceValidation();
        event.preventDefault();
        setIsSubmitting(true);
        //console.log(window.CollectJS.startPaymentRequest());
        getCreditCardToken(event);
        saveOrderDetails(event);
        saveBillingDetails(event);
        orderRef.current = order;
    };

    const enforceValidation = () => {
        const forms = document.querySelectorAll('.needs-validation')  // Loop over them and prevent submission
        Array.from(forms).forEach(form => {
            form.addEventListener('submit', event => {
                if (!form.checkValidity()) {
                    event.preventDefault()
                    event.stopPropagation()
                    //$(".is-invalid:first").focus();
                    window.scrollTo(0, 0);
                }
                form.classList.add('was-validated')
            }, false)
        });
    };

    const saveOrderDetails = (event) => {
        if (sameAddress) {
            order.supporterFirstName = event.target.firstName.value;
            order.supporterLastName = event.target.lastName.value;
            order.shippingAddress = event.target.address.value;
            order.shippingAddress2 = event.target.address2.value;
            order.shippingCity = event.target.city.value;
            order.shippingState = event.target.state.value;
            order.shippingZip = event.target.zip.value;
        } else {
            order.supporterFirstName = event.target.deliveryfirstName.value;
            order.supporterLastName = event.target.deliverylastName.value;
            order.shippingAddress = event.target.deliveryaddress.value;
            order.shippingAddress2 = event.target.deliveryaddress2.value;
            order.shippingCity = event.target.deliverycity.value;
            order.shippingState = event.target.deliverystate.value;
            order.shippingZip = event.target.deliveryzip.value;
        }
        order.supporterPhoneNumber = event.target.phone.value;
        order.donorEmail = event.target.email.value;

    }

    const saveBillingDetails = (event) => {
        order.payment.amount = order.orderTotal;
        order.payment.firstName = event.target.firstName.value;
        order.payment.lastName = event.target.lastName.value;
        order.payment.address1 = event.target.address.value;
        order.payment.address2 = event.target.address2.value;
        order.payment.city = event.target.city.value;
        order.payment.state = event.target.state.value;
        order.payment.zip = event.target.zip.value;
    }


    const getCreditCardToken = (event) => {
        var obj = {
            "holderName": event.target.holderName.value,
            "cardNumber": event.target.cardNumber.value,
            "cvv": event.target.cvv.value,
            "expiryMonth": event.target.expiryMonth.value,
            "expiryYear": event.target.expiryYear.value,
            "billingAddress": {
                "addressLine1": event.target.address.value,
                "addressLine2": event.target.address2.value,
                "city": event.target.city.value,
                "state": stateLookup[event.target.state.value],
                "zip": event.target.zip.value
            }
        }

        var raw = JSON.stringify(obj);

        var requestOptions = {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json'
            }),
            body: raw,
            redirect: 'follow'
        };

        fetch(order.gatewayUrl + "/v1/card/tokenize", requestOptions)
            .then(function (response) {
                // clear the CC errors out
                document.getElementById('ccErrors').innerHTML = ''

                return response.json();
            })
            .then(function (result) {
                if (result.hasOwnProperty('token')) {
                    console.log(result);
                    finishSubmit(result);
                }
                else {
                    setIsSubmitting(false);
                    console.log(result);
                    if (result.hasOwnProperty('errors')) {
                        result.errors.forEach(function (item) {
                            var liElement = document.createElement('li');
                            liElement.textContent = item.field + ": " + item.message;
                            document.getElementById('ccErrors').appendChild(liElement);
                        });
                    } else if (result.hasOwnProperty('errorMessage')) {
                        var liElement = document.createElement('li');
                        liElement.textContent = result.errorMessage;
                        document.getElementById('ccErrors').appendChild(liElement);
                    } else {
                        var liElement = document.createElement('li');
                        liElement.textContent = "An unknown payment error occurred";
                        document.getElementById('ccErrors').appendChild(liElement);
                    }
                }
            })
            .catch(function (error) {
                setIsSubmitting(false);
                var message = "Card Tokenization Error: /r/n" + error
                logMessage(message, 4)
                alert("An unknown payment error occurred")
                return console.log('error', error);
            });
    };

    const finishSubmit = (response) => {
        orderRef.current.payment.token = response.token;
        console.log(orderRef.current);

        OrdersService.ProcessOrder(orderRef.current).then(res => {
            console.log(res.data);
            if (res.data == 'success') {
                alert("Order Successful!");

                axios.post(orderRef.current.appSiteUrl + '/api/donor/digitalReceipt/' + orderRef.current.orderId).then(retUrl => {
                    console.log(retUrl);
                    window.location.href = retUrl.data;
                }).catch(e => {
                    window.location.href = "/";

                })
            }
            else {
                alert(res.data);
                setIsSubmitting(false);
            }
        }).catch(e => {
            var message = "Error Processing Order: /r/n" + e
            logMessage(message, 4)
            alert("Order was unsuccessful");
        });

        //setIsSubmitting(false);
        //this.setState({ isSubmitting: false, alertMessage: 'The form was submitted. Check the console to see the output data.' });
    };

    const logMessage = (message, level) => {
        var data = {
            message: message,
            level: level,
        };

        axios.post('/api/Logger/Log/', data)
            .then(response => {
                //    console.log("LOGGER: " + response.data);
            })
            .catch(error => {
                //    console.error("LOGGER: " + error);
            });
    };

    useEffect(x => {

        OrdersService.getFundraiserOrder(orderId).then((x) => {
            setOrder(x);
        });
        enforceValidation();


        // setTimeout(() => {
        //    
        // }, 100);


        return () => { };


    }, []);
    return (
        <>
            <h2>Checkout{(order && order.testMode ? " *TEST_MODE*" : "")}</h2>

            <div className="row g-5">
                <div className="col-md-7 col-lg-8">
                    <form className="needs-validation" noValidate onSubmit={handleSubmit}>
                        <h4 className="mb-3">Billing Address</h4>
                        <BillingAddressForm/>
                        <hr className="my-4" />
                        <div className="mb-3">
                            <h4 className="mb-3">Delivery Address</h4>
                            <FormGroup check className="mb-3">
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        defaultChecked={sameAddress}
                                        onChange={
                                            (e) => {
                                                setSameAddress(e.target.checked);
                                            }
                                        }
                                    />{""}
                                    <strong>Same as Billing Address</strong>
                                </Label>
                            </FormGroup>
                            <DeliveryAddressForm show={!sameAddress} />
                        </div>
                        <hr className="my-4" />
                        <h4>Payment</h4>
                        <div className="row gy-3">
                            <div className="col-md-6">
                                <label htmlFor="holderName" className="form-label">Name on card</label>
                                <input type="text" className="form-control" id="holderName" placeholder="" required />
                                <small className="text-muted">Full name as displayed on card</small>
                                <div className="invalid-feedback">
                                    Name on card is required
                                </div>
                            </div>

                            <div className="col-md-6">
                                <label htmlFor="cardNumber" required className="form-label">Credit card number</label>
                                <input type="text" className="form-control" id="cardNumber" placeholder="" required />
                                <div className="invalid-feedback">
                                    Card number is required
                                </div>
                            </div>

                            <div className="col-md-3">
                                <label htmlFor="expiryMonth" className="form-label">Exp. Month</label>
                                <input type="text" className="form-control" id="expiryMonth" placeholder="" required />
                                <small className="text-muted">2-digit month</small>
                                <div className="invalid-feedback">
                                    Card expiration month is required
                                </div>
                            </div>

                            <div className="col-md-3">
                                <label htmlFor="expiryYear" className="form-label">Exp. Year</label>
                                <input type="text" className="form-control" id="expiryYear" placeholder="" required />
                                <small className="text-muted">4-digit year</small>
                                <div className="invalid-feedback">
                                    Card expiration year is required
                                </div>
                            </div>

                            <div className="col-md-3">
                                <label htmlFor="cvv" className="form-label">CVV</label>
                                <input type="text" className="form-control" id="cvv" placeholder="" required />
                                <div className="invalid-feedback">
                                    Card CVV is required
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="text-danger" >
                                    <ul id="ccErrors"></ul>
                                </div>
                            </div>
                        </div>
                        <hr className="my-4" />
                        <h4>Order Summary</h4>
                        <OrderedTable order={order} />
                        <hr className="my-4" />
                        <button
                            className="w-100 btn btn-primary btn-lg" type="submit" disabled={isSubmitting}>Submit
                        </button>
                    </form>
                </div>
            </div>
        </>
    );

}
