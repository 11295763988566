import axios from "axios";

const AdminService = {
    API_ENDPOINT: '',
    async createBucket(bucketName) {
        return axios.post('api/AWSS3Controller/CreateBucketAsync',bucketName);
    },
    async uploadFile(file) {
        return axios.post('api/AWSS3Controller/UploadFileAsync', file);
    },
    async getAvailableVendorOffers(fundraiserId, filterState, currentTicket) {
        const response = await fetch('api/Admin/GetAvailableVendorOffers?fundraiserId=' + fundraiserId + '&currentTicket=' + currentTicket + '&&filterState=' + filterState);
        const data = await response.json();
        return data;
        //this.setState({ forecasts: data, loading: false });
    },
    async getThisVendorsOffers(vendorId) {
        const response = await fetch('api/Admin/GetThisVendorsOffers?vendorId=' + vendorId);
        const data = await response.json();
        return data;
        //this.setState({ forecasts: data, loading: false });
    },
    async getTeam(ticketId) {
        const response = await fetch('api/Admin/GetTeam?ticketId=' + ticketId);
        const data = await response.json();
        return data;
    },
    async getBaseUrl() {
        const response = await fetch('api/Admin/GetBaseUrl/');
        const data = await response.json();
        return data;
    },

    async getTicket(ticketId) {
        const response = await fetch('api/Admin/GetTicket?ticketId=' + ticketId);
        const data = await response.json();
        return data;
        //this.setState({ forecasts: data, loading: false });
    },

    async getTicketByFundraiser(fundraiserId) {
        const response = await fetch('api/Admin/GetTicketByFundraiser?fundraiserId=' + fundraiserId);
        const data = await response.json();
        return data;
        //this.setState({ forecasts: data, loading: false });
    },
    async getTickets() {
        const response = await fetch('api/Admin/GetTickets');
        const data = await response.json();
        return data;
        //this.setState({ forecasts: data, loading: false });
    },
    async getVendor(vendorId) {
        const response = await fetch('api/Admin/GetVendor?vendorId=' + vendorId);
        const data = await response.json();
        return data;
        //this.setState({ forecasts: data, loading: false });
    },
    async getVendorOffer(vendorOfferId, vendorId) {
        const response = await fetch('api/Admin/GetVendorOffer?vendorOfferId=' + vendorOfferId + '&vendorId=' + vendorId);
        const data = await response.json();
        return data;
        //this.setState({ forecasts: data, loading: false });
    },
    async getVendorOffers() {
        const response = await fetch('api/Admin/GetVendorOffers');
        const data = await response.json();
        return data;
        //this.setState({ forecasts: data, loading: false });
    },
    async getVendors() {
        const response = await fetch('api/Admin/GetVendors');
        const data = await response.json();
        return data;
        //this.setState({ forecasts: data, loading: false });
    },
    async saveVendor(vendor) {
        return axios.post('api/Admin/SaveVendor', vendor);
    },
    async saveVendorOffer(vendorOffer) {
        return axios.post('api/Admin/SaveVendorOffer', vendorOffer);
    },
    async saveTicket(ticket, fundraiserId) {
        //ticket.fileNames = fileNames;
        ticket.fundraiserId = fundraiserId;
        return axios.post('api/Admin/SaveTicket', ticket);
    },
    async createComplimentaryTicket(ticketId, qty) {
        const response = await fetch('api/Admin/CreateComplimentaryTicket?ticketId=' + ticketId + '&qty=' + qty);
        const data = await response.json();
        return data;
    },
    async getCurrentComplimentaryTickets(ticketId) {
        const response = await fetch(`api/Admin/GetCurrentComplimentaryTickets?ticketId=${ticketId}`);
        const data = await response.json();
        return data;
    },
    async revokeComplimentaryTicket(ticketPurchaseId) {
        const response = await fetch(`api/Admin/RevokeComplimentaryTicket?ticketPurchaseId=${ticketPurchaseId}`);
        const data = await response.json();
        return data;
    },
    async revokeAllComplimentaryTickets(ticketId) {
        const response = await fetch(`api/Admin/RevokeAllComplimentaryTickets?ticketId=${ticketId}`);
        const data = await response.json();
        return data;
    },
    async saveTeam(team) {
        return axios.post('api/Admin/SaveTeam', team);
    },
    async deleteTicket(ticket) {
        return axios.post('api/Admin/DeleteTicket', ticket);
    },
    //async uploadImage(image) {
    //    console.log('Upload clicked')
    //    // Get the presigned URL
    //    const uploadEndpointGET = await axios({
    //        method: 'GET',
    //        url: this.API_ENDPOINT
    //    })
    //    console.log('Response: ', uploadEndpointGET)
    //    console.log('Uploading: ', image)
    //    let binary = atob(image.split(',')[1])
    //    let array = []
    //    for (var i = 0; i < binary.length; i++) {
    //        array.push(binary.charCodeAt(i))
    //    }
    //    let blobData = new Blob([new Uint8Array(array)], { type: 'image/jpeg' })
    //    console.log('Uploading to: ', uploadEndpointGET.uploadURL)
    //    const imagePUT = await fetch(uploadEndpointGET.uploadURL, {
    //        method: 'PUT',
    //        body: blobData
    //    })
    //    console.log('Result: ', imagePUT)
    //    // Final URL for the user doesn't need the query string params
    //    uploadURL = uploadEndpointGET.uploadURL.split('?')[0]
    //},
}

export default AdminService;