import AdminService from "../services/AdminService";
import { Link, useParams } from 'react-router-dom';

import React, { useEffect, useState, } from 'react';
export default function Team() {
    //useParams
    const { ticketId } = useParams();
    //useState
    const [team, setTeam] = useState();

    useEffect(x => {
        /*GetTeam*/
        AdminService.getTicket(ticketId).then(() => {
           let a = {
                name: "Dogs",
                ticketId: 1,
                imgUrl: "asdf.com",
                imgFile: "",
                link1: "asdf.com",
                link2: "asdf.com",
                link3: "asdf.com"

            }
            setTeam(a)
            console.log(a);
        });

    }, []);
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(team);

        AdminService.saveTeam(team).then(x => console.log(x));
    }

    return (
        <div>
            <h2>{team?.name}</h2>
            <form onSubmit={handleSubmit} className="needs-validation pb-3" noValidate>

                <input type="text" className="form-control" id="id" placeholder=""
                    value={team?.name}
                    hidden
                    required
                />

                <input type="text" className="form-control" id="ticketId" placeholder=""
                    value={team?.ticketId}
                    hidden
                    required
                />
                <div className="row g-3 pb-4">
                    <div className="col-sm-6">
                        <label htmlFor="photo" className="form-label">Photo Url</label>
                        <input type="text" className="form-control" id="photo" placeholder=""
                            value={team?.imgUrl}
                            onChange={e => setTeam({ ...team, imgUrl: e.target.value })}
                            required
                        />
                        <div className="invalid-feedback">
                            Valid url is required.
                        </div>
                    </div>
                    </div>
                    <div className="row g-3 pb-4">
                        <div className="col-sm-6">
                            <label htmlFor="photoUpload" className="form-label">Photo File (.jpeg or .png)</label>
                            <input type="file" className="form-control" id="photoUpload" placeholder=""
                                value={team?.imgFile}
                                onChange={e => setTeam({ ...team, imgFile: e.target.value })}
                                required
                                accept="image/png, image/jpeg"
                            />
                            <div className="invalid-feedback">
                                Valid png or jpeg required.
                            </div>
                        </div>
                    <div className="col-sm-6">
                        <label htmlFor="link1" className="form-label">Link 1</label>
                        <input type="text" className="form-control" id="link1" placeholder=""
                            value={team?.link1}
                            onChange={e => setTeam({ ...team, link1: e.target.value })}
                            required
                        />
                        <div className="invalid-feedback">
                            Valid link1 is required.
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <label htmlFor="link2" className="form-label">Link 2</label>
                        <input type="text" className="form-control" id="link2" placeholder=""
                            value={team?.link2}
                            onChange={e => setTeam({ ...team, link2: e.target.value })}
                            required
                        />
                        <div className="invalid-feedback">
                            Valid link2 is required.
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <label htmlFor="link3" className="form-label">Link 3</label>
                        <input type="text" className="form-control" id="link3" placeholder=""
                            value={team?.link3}
                            onChange={e => setTeam({ ...team, link3: e.target.value })}
                            required
                        />
                        <div className="invalid-feedback">
                            Valid link3 is required.
                        </div>
                    </div>

                </div>
                <div className="d-flex">
                    <button className="btn btn-primary btn-lg col-6" type="submit">
                        Save
                    </button>
                </div>
            </form>
        </div>
    );
}
