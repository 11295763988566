import './counter.module.css';

function ItemCounter(props) {
    const count = props.quantity;
    const incrementCounter = () => props.increaseCount();
    const decrementCounter = () => props.decreaseCount();
    const disablePlus = props.disablePlus;
    const disableMinus = props.disableMinus;

    const countBox = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px',
        gap: '10px',
        width: '35px',
        height: '30px',
        width: '30px'
    };
    const buttonPrimary = {
        background: '#112D48',
        borderRadius: '20px',
        height: '30px',
        width: '30px',
        padding: '0',
        color: 'white',
        fontSize: '20px'

    }
    const buttonSecondary = {
        background: '#26C6DA',
        borderRadius: '20px',
        height: '30px',
        width: '30px',
        padding: '0',
        color: 'white',
        fontSize: '20px'


    }

    const Counter = {
        display: 'flex',
        flexDirection: 'row',
        height: 'auto',
        width: 'auto',
    }
    return (
        <div style={Counter}>
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.2/font/bootstrap-icons.css"></link>    
            <button style={buttonSecondary} type="button" disabled={disableMinus} onClick={decrementCounter} className="btn btn-light"><span className="bi bi-dash"></span></button>
            <p style={countBox}>{count}</p>
            <button style={buttonPrimary} type="button" disabled={disablePlus} onClick={incrementCounter}  className="btn btn-light"><span className="bi bi-plus"></span></button>
        </div>
    );
}

export default ItemCounter;
