import { Link } from 'react-router-dom';
export default function OrderedTable(props) {
    const order = props.order;

    if (!order) {
        // Show a loading message or spinner
        return <div>Loading...</div>;
    }
    const paymentType = props.paymentType;
    const roundToHundredth = (value) => {
        let placeMaker = value * 100;
        return (placeMaker / 100).toFixed(2);
    };
    
    // Sum up the shipping costs provided by the server for each item
    let totalShippingCost = 0;
    if (order && order.fundraiserOrderItems) {
        order.fundraiserOrderItems.forEach(item => {
            totalShippingCost += item.shippingCost;
        });
    }
    // Calculate the total with shipping and the convenience fee
    const totalWithShipping = order ? order.orderTotal + totalShippingCost : 0;
    console.log(order.fundraiserOrderItems);
    const convenienceFee = (!paymentType || (paymentType === 2)) ? roundToHundredth(totalWithShipping * 0.05) : 0;
    const finalTotal = totalWithShipping + parseFloat(convenienceFee);


    return (<table className="table border">
        <tbody>
            <tr>
                <th>Name</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Total</th>
                <th></th>
            </tr>
            {order && order.fundraiserOrderItems.map((item) => {
                return (
                    item.fundraiserOrderItemOptions.map((itemOption, index) => {
                        if (itemOption.qty > 0)
                            return (
                                <tr key={index} className="border">
                                    <td>{itemOption.name}</td>
                                    <td>{itemOption.qty}</td>
                                    <td>${itemOption.price}</td>
                                    <td>${itemOption.price * itemOption.qty}</td>
                                    <td><Link type="button" className="border rounded p-5 px-20 bg-info text-white" to={"/Order/Edit/" + order.orderId}>Edit</Link></td>
                                </tr>
                            )
                    })
                )
            })}
            {
                order && order.isDonate &&
                        
                            <tr>
                                <td><emp>Donation</emp></td>
                                <td></td>
                                <td></td>
                        <td><strong>${order && roundToHundredth(order.orderTotal)}</strong></td>
                                <td><Link type="button" className="border rounded p-5 px-20 bg-info text-white" to={"/Order/Edit/" + order.orderId}>Edit</Link></td>
                            </tr>
                       
               
            }
            {/* Total Shipping Cost Row */}
            <tr>
                <td colSpan="3">Shipping</td>
                <td>${roundToHundredth(totalShippingCost)}</td>
                <td></td>
            </tr>
            {convenienceFee > 0 &&
                <tr>
                    <td colSpan="3">Convenience Fee - 5%</td>
                    <td>${convenienceFee}</td>
                    <td></td>
                </tr>
            }
            <tr>
                <td><strong>Total</strong></td>
                <td></td>
                <td></td>
                <td><strong>${roundToHundredth(finalTotal)}</strong></td>
                <td></td>
            </tr>
        </tbody>
    </table>)
}